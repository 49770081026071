import React from 'react';
import { useTranslations } from '@unisporkal/localization';
import { useSiteMap } from '@unisporkal/sites';
import {
  trackShowPremiumPlusVideoPromo,
  trackClickPremiumPlusVideoPromoCTA,
  trackShowPremiumPlusVideoUpgrade,
  trackClickPremiumPlusVideoUpgradeCTA,
} from './PremiumPlusVideoPromoTracking';
import styles from '../PromotionalContent.module.scss';
import usePlanInfoQuery from '../../../../../hooks/queries/usePlanInfoQuery';

const PremiumPlusVideoPromo = () => {
  const { data: planInfoQuery } = usePlanInfoQuery();
  const t = useTranslations();
  const siteMap = useSiteMap();
  const { showPremiumPlusVideoUpgrade, showPremiumPlusVideoMessage } =
    planInfoQuery.ppvMessaging;

  const premiumPlusVideoPromo = () => {
    if (!showPremiumPlusVideoMessage) {
      return null;
    }

    let bestFootagePrices = t('get_our_best_prices_on_footage');
    bestFootagePrices += ' ';
    let displayData = {};

    if (showPremiumPlusVideoUpgrade) {
      displayData = {
        class: 'premiumPlusVideoUpgradeCta',
        href: siteMap.plansAndPricingSelfServe(),
        cta: 'upgrade_now_cta_label',
        testId: 'upgradeVideoPromo',
        trackClick: trackClickPremiumPlusVideoUpgradeCTA,
      };
      trackShowPremiumPlusVideoUpgrade();
    } else {
      displayData = {
        class: 'premiumPlusVideoPromoCta',
        href: siteMap.plansAndPricingPremiumPlusVideoPrices(),
        cta: 'learn_more_header',
        testId: 'basicVideoPromo',
        trackClick: trackClickPremiumPlusVideoPromoCTA,
      };
      trackShowPremiumPlusVideoPromo();
    }

    return (
      <>
        <div
          className={styles.promo}
          data-testid="premiumPlusVideoPromo"
        >
          <figure className={styles.icon} />

          <div data-testid="premiumPlusVideoContainer">
            <p>
              {bestFootagePrices}
              <a
                className={styles[displayData.class]}
                href={displayData.href}
                data-testid={displayData.testId}
                onClick={displayData.trackClick}
              >
                {t(displayData.cta)}
              </a>
            </p>
          </div>
        </div>

        <hr className={styles.horizontalDivider} />
      </>
    );
  };

  return <>{premiumPlusVideoPromo()}</>;
};

export default PremiumPlusVideoPromo;
