import React from 'react';
import { useSiteMap } from '@unisporkal/sites';
import { ga4Track, navigationEvent } from '@unisporkal/ga4';
import HeaderLogo from '../HeaderLogo/HeaderLogo';
import VisualGpsLogo from '../../../icons/visualGPSiStockLogo.svg?icon';
import VisualGpsMobileLogo from '../../../icons/visualGPSMobileLogo.svg?icon';
import styles from './MinimalHeader.module.scss';

interface MinimalHeaderProps {
  isVisualGps?: boolean;
  linkDisabled?: boolean;
}

const MinimalHeader = ({
  isVisualGps = false,
  linkDisabled = false,
}: MinimalHeaderProps) => {
  const siteMap = useSiteMap();
  const href = siteMap.visualGpsLanding();
  const navigationTracking = () => {
    const ga4Event = navigationEvent({
      link_text: 'visual_gps_insights',
      link_url: href,
      ui_element_location: 'header',
      navigation_selection: 'visual-gps-header',
    });
    ga4Track(ga4Event);
  };

  const minimalHeaderContent = () => <HeaderLogo linkDisabled={linkDisabled} />;
  const visualGpsContent = () => (
    <a
      data-testid="nav-link-visual_gps_insights"
      href={href}
      onClick={navigationTracking}
    >
      <VisualGpsLogo
        role="img"
        className={styles.desktop}
        aria-label="iStock Visual GPS Logo"
        data-testid="visualGpsLogo"
      />
      <VisualGpsMobileLogo
        role="img"
        className={styles.mobile}
        aria-label="iStock Visual GPS Mobile Logo"
        data-testid="visualGpsMobileLogo"
      />
    </a>
  );

  return (
    <header
      data-testid="minimal-header"
      className={styles.container}
    >
      {isVisualGps ? visualGpsContent() : minimalHeaderContent()}
    </header>
  );
};

export default MinimalHeader;
