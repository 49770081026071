import React from 'react';
import { List } from '@unisporkal/alliance-ui-list';
import { useLocale } from '@unisporkal/localization';
import useIstockLinks from '../../../../hooks/useIstockLinks';
import ListOfLinks from '../ListOfLinks/ListOfLinks';

import styles from './ResourcesDropdown.module.scss';

const ResourcesDropdown = () => {
  const locale = useLocale();
  const links = useIstockLinks();
  const englishLocale = locale === 'en-gb' || locale === 'en-us';
  // TODO: Update link paths below once redesigned header is permanent
  // https://app.clickup.com/t/86ayhcvmb
  const resourcesLinksList = links.resources();
  const modifiedResourcesLinksList = links.modifiedResources();

  if (!englishLocale) {
    return (
      <List
        className={styles.container}
        data-testid="openResourcesDropdown"
      >
        <ListOfLinks
          links={modifiedResourcesLinksList}
          gaListId="resources"
          resourcesLinks
        />
      </List>
    );
  }
  return (
    <List
      className={styles.container}
      data-testid="openResourcesDropdown"
    >
      <ListOfLinks
        links={resourcesLinksList}
        gaListId="resources"
        resourcesLinks
      />
    </List>
  );
};

export default ResourcesDropdown;
