/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { ga4Track, notificationInteraction } from '@unisporkal/ga4';
import { RawHtml } from '@unisporkal/utilities';
import propTypes from 'prop-types';
import { Link } from '@unisporkal/alliance-ui-link';

import addSkuToCart from '../../../../services/checkoutService';
import styles from './YourPlansSection.module.scss';

const trackShowNotification = (messageData) => {
  const ga4Event = notificationInteraction({
    event: 'notification_shown',
    notification_description: messageData.ga4NotificationDescription,
    notification_type: 'information',
    cta_text: undefined,
    ui_element_location: 'account_panel',
    item_id: messageData.ga4Sku,
  });
  ga4Track(ga4Event);
};

const trackNotificationCtaClick = (messageData) => {
  const ga4Event = notificationInteraction({
    event: 'notification_cta_click',
    notification_description: messageData.ga4NotificationDescription,
    notification_type: 'information',
    cta_text: messageData.ga4CtaText,
    ui_element_location: 'account_panel',
    item_id: messageData.ga4Sku,
  });
  ga4Track(ga4Event);
};

const CustomMediaAlert = ({ messageData = {} }) => {
  const displayAlert = messageData && Object.keys(messageData).length > 0;

  useEffect(() => {
    if (displayAlert) {
      trackShowNotification(messageData);
    }
  }, [displayAlert]);

  if (!displayAlert) {
    return null;
  }

  const handleCtaClick = async (event) => {
    event.stopPropagation();
    trackNotificationCtaClick(messageData);

    const { checkoutHref, expiredAgreement, aTagHref } = messageData;

    if (checkoutHref) {
      const sku = expiredAgreement[0]?.sku;
      const response = await addSkuToCart(sku);
      if (response.status === 200) {
        window.location.href = aTagHref;
      }
    }
  };

  return (
    <div
      className={styles.agreementAlert}
      data-testid="agreementAlert"
    >
      <figure />
      <p data-testid="agreementText">
        <RawHtml>{messageData.pTagIloxKey}</RawHtml>
        <Link
          href={messageData.aTagHref}
          onClick={(event) => handleCtaClick(event)}
          className={styles.agreementLink}
          data-testid="agreementCta"
        >
          <span>{messageData.aTagIloxKey}</span>
        </Link>
      </p>
    </div>
  );
};

CustomMediaAlert.propTypes = {
  messageData: propTypes.shape().isRequired,
};

export default CustomMediaAlert;
