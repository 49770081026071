import { AssetCategoryType } from '@unisporkal/sites';
import agreementWarnings from './agreementWarnings';

export default class Links {
  constructor(
    siteMap,
    searchPathBuilder,
    t,
    popularCategories,
    seasonalContent,
    cacheSafeCountryCode,
    hasActiveGenerationAgreement,
    aiGenSubnav
  ) {
    this.siteMap = siteMap;
    this.searchPathBuilder = searchPathBuilder;
    this.t = t;
    this.popularCategories = popularCategories;
    this.seasonalContent = seasonalContent;
    this.cacheSafeCountryCode = cacheSafeCountryCode;
    this.hasActiveGenerationAgreement = hasActiveGenerationAgreement;
    this.aiGenSubnav = aiGenSubnav;
  }

  moreToDiscoverVideo() {
    return [
      {
        gaName: 'artist_of_the_month',
        name: this.t('site_specific.istock.artist_of_the_month'),
        href: this.siteMap.signatureArtist('urls.footage'),
      },
      {
        gaName: 'understanding_royalty_free',
        name: this.t('site_specific.istock.understanding_royalty_free'),
        href: this.siteMap.royaltyFreeImages(),
      },
      {
        gaName: 'free_video_of_the_week',
        name: this.t('site_specific.istock.free_video_of_the_week'),
        href: this.siteMap.freeVideos(),
      },
      {
        gaName: 'istock_iq_blog',
        name: this.t('site_specific.istock.istock_iq_blog'),
        href: this.siteMap.istockBlog(),
        target: '_blank',
        rel: 'noopener noreferrer',
      },
    ];
  }

  moreToDiscoverPhotos() {
    return [
      {
        gaName: 'artist_of_the_month',
        name: this.t('site_specific.istock.artist_of_the_month'),
        href: this.siteMap.signatureArtist('urls.stock-photos'),
      },
      {
        gaName: 'understanding_royalty_free',
        name: this.t('site_specific.istock.understanding_royalty_free'),
        href: this.siteMap.royaltyFreeImages(),
      },
      {
        gaName: 'free_photo_of_the_week',
        name: this.t('site_specific.istock.free_photo_of_the_week'),
        href: this.siteMap.freePhotos(),
      },
      {
        gaName: 'istock_iq_blog',
        name: this.t('site_specific.istock.istock_iq_blog'),
        href: this.siteMap.istockBlog(),
        target: '_blank',
        rel: 'noopener noreferrer',
      },
    ];
  }

  moreToDiscoverIllustrations() {
    return [
      {
        gaName: 'artist_of_the_month',
        name: this.t('site_specific.istock.artist_of_the_month'),
        href: this.siteMap.signatureArtist('urls.stock-illustrations'),
      },
      {
        gaName: 'understanding_royalty_free',
        name: this.t('site_specific.istock.understanding_royalty_free'),
        href: this.siteMap.royaltyFreeImages(),
      },
      {
        gaName: 'free_illustration_of_the_week',
        name: this.t('site_specific.istock.free_illustration_of_the_week'),
        href: this.siteMap.freeIllustrations(),
      },
      {
        gaName: 'istock_iq_blog',
        name: this.t('site_specific.istock.istock_iq_blog'),
        href: this.siteMap.istockBlog(),
        target: '_blank',
        rel: 'noopener noreferrer',
      },
    ];
  }

  moreToDiscoverVectors() {
    return [
      {
        gaName: 'understanding_royalty_free',
        name: this.t('site_specific.istock.understanding_royalty_free'),
        href: this.siteMap.royaltyFreeImages(),
      },
      {
        gaName: 'free_illustration_of_the_week',
        name: this.t('site_specific.istock.understanding_vectors'),
        href: this.siteMap.faqUsingFiles(AssetCategoryType.VECTORS),
      },
      {
        gaName: 'istock_iq_blog',
        name: this.t('site_specific.istock.istock_iq_blog'),
        href: this.siteMap.istockBlog(),
        target: '_blank',
        rel: 'noopener noreferrer',
      },
    ];
  }

  getCategoryLinks(assetType) {
    const categoryLinks = [
      ...this.popularCategories[assetType].map((item) => ({
        name: item.localized_category,
        href: item.url,
        gaName: item.category.replace(/\s/g, '_').toLowerCase(),
      })),
    ];

    return categoryLinks;
  }

  // Seasonal Links are used in the Curated Content Section
  getSeasonalContentLinks(assetCategoryType) {
    // this.seasonalContent is already filtered by locale unlike the header package
    // please delete this comment once the header package is retired
    if (!this.seasonalContent || !this.seasonalContent[assetCategoryType])
      return [];

    return (
      this.seasonalContent[assetCategoryType]
        // older seasonal content doesn't have expiration dates, so added logic to filter these
        .filter((item) => {
          const expirationDate = item.expirationDate
            ? new Date(item.expirationDate)
            : null;
          const startDate = item.startDate ? new Date(item.startDate) : null;
          const afterStartDate = !startDate || startDate <= new Date();
          const beforeExpirationDate =
            !expirationDate || expirationDate > new Date();
          return afterStartDate && beforeExpirationDate;
        })
        .map((item) => ({
          name: item.localizedLabel,
          gaName: item.englishLabel.replace(/\s/g, '_').toLowerCase(),
          href:
            item.localizedPath ||
            this.searchPathBuilder.simpleSearchPath(
              assetCategoryType,
              item.localizedLabel
            ),
        }))
    );
  }

  curatedSets(assetCategoryType) {
    return {
      gaName: 'curated_sets',
      name: this.t('site_specific.istock.curated_sets'),
      href: this.siteMap?.curatedContent?.(assetCategoryType),
      hideMobileVideo: true,
    };
  }

  curatedCollections(assetCategoryType) {
    return [
      {
        gaName: 'signature_collection',
        name: this.t('signature_collection'),
        href: this.siteMap.signatureCollection(assetCategoryType),
      },
      {
        gaName: 'essentials_collection',
        name: this.t('essentials_collection'),
        href: this.siteMap.essentialsCollection(assetCategoryType),
      },
    ];
  }

  diversityAndInclusion() {
    return {
      gaName: 'diversity_and_inclusion',
      name: this.t('site_specific.istock.diversity_and_inclusion'),
      href: this.siteMap.diversity(),
    };
  }

  curatedContent(assetCategoryType) {
    switch (assetCategoryType) {
      case AssetCategoryType.ILLUSTRATIONS:
        return [
          this.curatedSets(assetCategoryType),
          ...this.curatedCollections(assetCategoryType),
          ...this.getSeasonalContentLinks(assetCategoryType),
        ];
      case AssetCategoryType.VECTORS:
        return [
          this.curatedSets(assetCategoryType),
          ...this.curatedCollections(assetCategoryType),
          ...this.getSeasonalContentLinks(assetCategoryType),
        ];
      case AssetCategoryType.VIDEOS:
        return [
          ...this.curatedCollections(assetCategoryType),
          ...this.getSeasonalContentLinks(assetCategoryType),
        ];
      default:
        return [
          this.curatedSets(assetCategoryType),
          ...this.curatedCollections(assetCategoryType),
          this.diversityAndInclusion(),
          ...this.getSeasonalContentLinks(assetCategoryType),
        ];
    }
  }

  resources() {
    return [
      {
        gaName: 'visual_gps_insights',
        name: this.t('search_trends'),
        href: this.siteMap.visualGpsLanding(),
        target: '_blank',
      },
      {
        gaName: 'top_image_searches',
        name: this.t('top_image_searches'),
        href: this.siteMap.mostPopularSearches(
          AssetCategoryType.IMAGES,
          this.cacheSafeCountryCode
        ),
      },
      {
        gaName: 'top_video_searches',
        name: this.t('top_video_searches'),
        href: this.siteMap.mostPopularSearches(
          AssetCategoryType.VIDEOS,
          this.cacheSafeCountryCode
        ),
      },
      {
        gaName: 'search_guide',
        name: this.t('istock_search_guide'),
        href: this.siteMap.istockSearchGuide(),
      },
      {
        gaName: 'reverse_image_search',
        name: this.t('reverse_image_search'),
        href: this.siteMap.reverseImageSearch(),
      },
      {
        gaName: 'reverse_video_search',
        name: this.t('reverse_video_search'),
        href: this.siteMap.reverseVideoSearch(),
      },
      {
        gaName: 'online_photo_editor',
        name: this.t('online_photo_editor'),
        href: this.siteMap.onlinePhotoEditor(),
      },
      {
        gaName: 'plugins_and_apps',
        name: this.t('plugins_and_apps'),
        href: this.siteMap.pluginsAndApps(),
      },
      {
        gaName: 'blog',
        name: this.t('blog'),
        href: this.siteMap.istockBlog(),
      },
      { gaName: 'faq', name: this.t('faq'), href: this.siteMap.faq() },
    ];
  }

  // Reverse Searches Links (currently an english-only feature) are not included in this list
  modifiedResources() {
    return [
      {
        gaName: 'visual_gps_insights',
        name: this.t('search_trends'),
        href: this.siteMap.visualGpsLanding(),
        target: '_blank',
      },
      {
        gaName: 'top_image_searches',
        name: this.t('top_image_searches'),
        href: this.siteMap.mostPopularSearches(
          AssetCategoryType.IMAGES,
          this.cacheSafeCountryCode
        ),
      },
      {
        gaName: 'top_video_searches',
        name: this.t('top_video_searches'),
        href: this.siteMap.mostPopularSearches(
          AssetCategoryType.VIDEOS,
          this.cacheSafeCountryCode
        ),
      },
      {
        gaName: 'search_guide',
        name: this.t('istock_search_guide'),
        href: this.siteMap.istockSearchGuide(),
      },
      {
        gaName: 'online_photo_editor',
        name: this.t('online_photo_editor'),
        href: this.siteMap.onlinePhotoEditor(),
      },
      {
        gaName: 'plugins_and_apps',
        name: this.t('plugins_and_apps'),
        href: this.siteMap.pluginsAndApps(),
      },
      {
        gaName: 'blog',
        name: this.t('blog'),
        href: this.siteMap.istockBlog(),
      },
      { gaName: 'faq', name: this.t('faq'), href: this.siteMap.faq() },
    ];
  }

  showAIGenHistoryLink() {
    return this.hasActiveGenerationAgreement;
  }

  userAccountNav() {
    return [
      {
        href: this.siteMap.account(),
        dataUiLocation: 'header_side_panel_right',
        iLoxKey: 'overview',
        class: 'overview',
        isDisplay: true,
        ga4Name: 'Overview',
      },
      {
        href: this.siteMap.accountDownload(),
        dataUiLocation: 'header_side_panel_right',
        iLoxKey: 'download_history',
        class: 'downloadHistory',
        isDisplay: true,
        ga4Name: 'Download history',
      },
      {
        href: this.siteMap.aiGenerationHistory(),
        dataUiLocation: 'header_side_panel_right',
        iLoxKey: 'generation_history',
        class: 'generationHistory',
        isDisplay: this.showAIGenHistoryLink(),
        ga4Name: 'Generation history',
      },
      {
        href: this.siteMap.accountProfile(),
        dataUiLocation: 'header_side_panel_right',
        iLoxKey: 'account_details',
        class: 'accountDetails',
        isDisplay: true,
        ga4Name: 'Account details',
      },
    ];
  }

  userAccountProjects() {
    return [
      {
        href: this.siteMap.imageEditor(),
        dataUiLocation: 'header_side_panel_right',
        iLoxKey: 'image_projects',
        class: 'imageProjects',
        isDisplay: true,
        ga4Name: 'Image projects',
      },
      {
        href: this.siteMap.cleanPath('/collaboration/boards'),
        dataUiLocation: 'header_side_panel_right',
        iLoxKey: 'boards',
        class: 'boards',
        isDisplay: true, // only show for mobile via css
        ga4Name: 'Boards',
      },
    ];
  }

  userAccountSubscriptionWarning(subscriptionInfo) {
    const {
      subscriptionWarningMessageType,
      subscriptionDaysRange,
      resumeDate,
      noDownloadsRemaining,
    } = subscriptionInfo;
    const dataObj = {
      pTagIloxKey: '',
      aTagIloxKey: '',
      aTagDataNav: '',
      aTagDataUILocation: 'header_side_panel_right',
      aTagHref: this.siteMap.account(),
      ga4NotificationDescription: undefined,
      ga4CtaText: undefined,
      hasATag: true,
      hasAFigure: true,
      isDisplay: true,
      hasNoWrapCta: false,
    };

    switch (subscriptionWarningMessageType) {
      case 'showExpired':
        dataObj.pTagIloxKey = this.t('your_subscription_has_expired');
        dataObj.aTagIloxKey = this.t('renew_today_arrow');
        dataObj.aTagDataNav = 'nav_Account_renew_today_post_expiration';
        dataObj.ga4NotificationDescription = 'subscription_expired';
        dataObj.ga4CtaText = 'renew_now';
        break;
      case 'showMonthlyExpired':
        dataObj.pTagIloxKey = this.t('your_subscription_has_expired');
        dataObj.aTagIloxKey = this.t('renew_today_arrow');
        dataObj.aTagDataNav = 'nav_Account_renew_today_post_expiration';
        dataObj.ga4NotificationDescription = 'subscription_expired';
        dataObj.ga4CtaText = 'renew_now';
        break;
      case 'subscriptionPaused':
        dataObj.pTagIloxKey = this.t('your_subscription_is_paused_resume_on', {
          zero: '<strong>',
          one: '</strong>',
          resumeDate,
        });
        dataObj.aTagIloxKey = `${this.t('view_details_cta')} >`;
        dataObj.hasNoWrapCta = true;
        dataObj.aTagDataNav = 'nav_Account_subscription_paused';
        dataObj.ga4NotificationDescription = 'subscription_paused';
        dataObj.ga4CtaText = 'view_details';
        break;
      case 'subscriptionCanUnsuspend':
        dataObj.pTagIloxKey = this.t('suspended_due_to_an_outstanding_balance');
        dataObj.aTagIloxKey = `${this.t('pay_now')} >`;
        dataObj.hasNoWrapCta = true;
        dataObj.aTagDataNav = 'nav_Account_unsuspend_subscription';
        dataObj.ga4NotificationDescription = 'unsuspend_subscription';
        dataObj.ga4CtaText = 'pay_now';
        break;
      case 'subscriptionSuspended':
        dataObj.pTagIloxKey = this.t('your_subscription_is_suspended');
        dataObj.aTagIloxKey = `${this.t('view_details_cta')} >`;
        dataObj.hasNoWrapCta = true;
        dataObj.aTagDataNav = 'nav_Account_subscription_suspended';
        dataObj.ga4CtaText = 'view_details';
        dataObj.ga4NotificationDescription = 'subscription_suspended';
        break;
      case 'subscriptionExpiring':
        if (subscriptionDaysRange !== 1) {
          dataObj.pTagIloxKey = this.t(
            'your_subscription_is_ending_in_x_days',
            {
              zero: '<strong>',
              one: '</strong>',
              num_days: subscriptionDaysRange,
            }
          );
        } else if (subscriptionDaysRange === 1) {
          dataObj.pTagIloxKey = this.t('subscription_ends_today');
        }

        dataObj.aTagIloxKey = this.t('renew_today_arrow');
        dataObj.aTagDataNav = 'nav_Account_renew_today';
        dataObj.ga4NotificationDescription = 'subscription_expiring';
        dataObj.ga4CtaText = 'renew_now';
        break;
      case 'showRenewZeroDownload':
        dataObj.pTagIloxKey = this.t('youre_out_of_downloads', {
          links: [
            {
              href: this.siteMap.account(),
              'data-ui-location': 'header_side_panel_right',
              'data-nav': 'nav_Account_renew_now_subscription_images_depleted',
            },
          ],
        });
        dataObj.hasATag = false;
        dataObj.hasAFigure = false;
        dataObj.isDisplay = noDownloadsRemaining;
        dataObj.ga4NotificationDescription = 'subscription_images_depleted';
        dataObj.ga4CtaText = 'renew_now';
        break;
      default:
        return null;
    }
    return dataObj;
  }

  customMediaAccountAlert(alertType, expiredAgreement) {
    return agreementWarnings(this.t, this.siteMap, alertType, expiredAgreement);
  }

  getSignInJoinLinks(currentLocation) {
    return {
      signInLink: {
        gaName: 'sign_in',
        name: this.t('sign_in'),
        href: this.siteMap.signInPath(encodeURIComponent(currentLocation)),
      },
      joinLink: {
        gaName: 'join',
        name: this.t('join'),
        href: this.siteMap.pathRef('join'),
      },
      signInAccountPanelLink: {
        gaLocation: 'account_panel',
        gaName: 'Sign in',
        name: this.t('sign_in'),
        href: this.siteMap.signInPath(encodeURIComponent(currentLocation)),
      },
      joinAccountPanelLink: {
        gaLocation: 'account_panel',
        gaName: 'Join',
        name: this.t('join'),
        href: this.siteMap.pathRef('join'),
      },
      yourBoards: {
        gaName: 'your_boards',
        name: this.t('your_boards'),
        href: this.siteMap.boardsDefault(),
      },
    };
  }

  getMusicLink() {
    return {
      gaName: 'music',
      name: this.t('music'),
      href: this.siteMap.music(),
    };
  }

  getPricingLink() {
    return {
      gaName: 'pricing',
      name: this.t('pricing'),
      href: this.siteMap.plansAndPricing(),
    };
  }

  getVisualGpsLink(target = null) {
    return {
      gaName: 'visual_gps_insights',
      name: this.t('search_trends'),
      href: this.siteMap.visualGpsLanding(),
      ...(target && { target }),
    };
  }

  getAIGenHref() {
    if (this.hasActiveGenerationAgreement) {
      return this.siteMap.aiGenerator();
    }
    return this.siteMap.aiLanding();
  }

  getAIGenerationLink() {
    return {
      gaName: 'ai_generator',
      name: this.t('ai_generator_title_casing'),
      href: this.getAIGenHref(),
    };
  }

  getMobileVideoLink() {
    return {
      gaName: 'videos_home',
      name: this.t('site_specific.istock.videos_home'),
      href: this.siteMap.video(),
    };
  }

  getMobileIllustrationsLink() {
    return {
      gaName: 'illustrations_home',
      name: this.t('site_specific.istock.illustrations_home'),
      href: this.siteMap.illustrations(),
    };
  }

  getMobileVectorsLink() {
    return {
      gaName: 'vectors_home',
      name: this.t('site_specific.istock.vectors_home'),
      href: this.siteMap.vectors(),
    };
  }

  getMobilePhotosLink() {
    return {
      gaName: 'photos_home',
      name: this.t('site_specific.istock.photos_home'),
      href: this.siteMap.photos(),
    };
  }

  // Creates links for ai generation subnav.
  // This includes this.getAIGenerationLink(), but will maintain that method for backwards compatibility.
  getAIGeneratorSubNavLinks() {
    const conditionalLinks = this.hasActiveGenerationAgreement
      ? [
          {
            gaName: 'ai_generator',
            name: this.t('ai_generator_title_casing'),
            href: this.siteMap.aiGenerator(),
          },
        ]
      : [];

    const aiGenSubNavLinks = [
      {
        gaName: 'overview_and_pricing',
        nameKey: 'overview_and_pricing',
        hrefMethod: 'aiLanding',
      },
      {
        gaName: 'generating_new_images',
        nameKey: 'ai_generate_new_images',
        hrefMethod: 'aiLandingGeneration',
      },
      {
        gaName: 'modifying_istock_images',
        nameKey: 'ai_modify_images',
        hrefMethod: 'aiLandingModification',
      },
      {
        gaName: 'ai_generator_user_guide',
        nameKey: 'ai_generator_user_guide',
        hrefMethod: 'aiUserGuide',
      },
      {
        gaName: 'whats_new',
        nameKey: 'whats_new',
        hrefMethod: 'aiNewFeatures',
      },
      { gaName: 'ai_faqs', nameKey: 'faq', hrefMethod: 'aiGenerationFaq' },
      { gaName: 'ai_learn', nameKey: 'learn', hrefMethod: 'aiLearn' },
    ].map((option) => ({
      gaName: option.gaName,
      name: this.t(option.nameKey),
      href: this.siteMap[option.hrefMethod](),
    }));

    return [...conditionalLinks, ...aiGenSubNavLinks];
  }
}
