import React from 'react';
import { ga4Track, navigationEvent } from '@unisporkal/ga4';

interface LinkProps {
  href: string;
  gaName: string;
  name: string;
}

interface MobileNavLinkProps {
  link: LinkProps;
}

const MobileNavLink = ({ link }: MobileNavLinkProps) => {
  const navigationTracking = () => {
    const ga4Event = navigationEvent({
      link_text: link.gaName,
      link_url: link.href,
      ui_element_location: 'header',
      navigation_selection: link.gaName,
    });
    ga4Track(ga4Event);
  };

  return (
    <a
      href={link.href}
      onClick={navigationTracking}
    >
      {link.name}
    </a>
  );
};

export default MobileNavLink;
