import React from 'react';
import propTypes from 'prop-types';
import styles from './RightPanel.module.scss';

const RightPanel = ({ showPanel, closePanel, children }) => {
  const rightPanelClass = showPanel ? styles.openMenu : styles.closeMenu;

  return (
    <nav
      className={rightPanelClass}
      data-testid="AccountPanelRight"
    >
      <button
        type="button"
        className={styles.closeButton}
        onClick={closePanel}
        data-testid="account_panel_close"
      />
      {children}
    </nav>
  );
};

export default RightPanel;

RightPanel.propTypes = {
  showPanel: propTypes.bool.isRequired,
  children: propTypes.node.isRequired,
  closePanel: propTypes.func.isRequired,
};
