import React, { useEffect, useRef, lazy, Suspense } from 'react';
import propTypes from 'prop-types';
import { useTranslations } from '@unisporkal/localization';
import { useModal } from '@unisporkal/modal';
import { useGtm } from '@unisporkal/tracking';
import styles from './YourPlansSection.module.scss';

const OnboardingVideoModal = lazy(() => import('./OnboardingVideoModal'));

const OnboardingVideoPanel = ({ isPPV, closePanel }) => {
  const t = useTranslations();
  const { showModal } = useModal();
  const { trackEvent, trackViewed } = useGtm();
  const containerRef = useRef(null);

  const trackingShown = trackViewed(OnboardingVideoPanel.GA_PAYLOAD_SHOWN);

  useEffect(() => {
    window.tracking_data.merchandising_units =
      'account_pannel_onboarding_video';
  }, []);

  useEffect(() => {
    trackingShown.sendTracking(containerRef);
  }, [containerRef]);

  const handleClick = () => {
    showModal(
      <Suspense>
        <OnboardingVideoModal isPPVSub={isPPV} />
      </Suspense>
    );
    trackEvent(...OnboardingVideoPanel.GA_PAYLOAD_CLICK);
    closePanel();
  };

  return (
    <div
      className={styles.onboardingVideoPanel}
      ref={containerRef}
    >
      <p>{t('learn_with_video_tour')}</p>
      <button
        onClick={handleClick}
        type="button"
        data-testid="onboarding-video-account-panel-cta"
      >
        {t('take_the_tour')}
      </button>
    </div>
  );
};

OnboardingVideoPanel.GA_PAYLOAD_SHOWN = {
  interactionName: 'merchUnitInteraction',
  interactionDetails: {
    ui_element_location: 'header_account_panel',
    merch_unit_interaction: 'Shown',
    merch_unit_selection: 'video_onboarding_post_purchase',
  },
};

OnboardingVideoPanel.GA_PAYLOAD_CLICK = [
  'merchUnitInteraction',
  {
    ui_element_location: 'header_account_panel',
    merch_unit_interaction: 'Click',
    merch_unit_selection: 'video_onboarding_post_purchase',
  },
];

OnboardingVideoPanel.propTypes = {
  isPPV: propTypes.bool.isRequired,
  closePanel: propTypes.func.isRequired,
};

export default OnboardingVideoPanel;
