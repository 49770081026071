import axios from 'axios';

async function addSkuToCart(sku) {
  const url = '/components/plans-and-pricing/api/cart/ai-gen';

  try {
    const response = await axios.put(url, { sku });
    return { status: response.status, data: response.data };
  } catch (error) {
    return Promise.reject(error);
  }
}

export default addSkuToCart;
