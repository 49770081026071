import React from 'react';
import propTypes from 'prop-types';
import { useTranslations } from '@unisporkal/localization';
import styles from './Hamburger.module.scss';

const Hamburger = ({ showPanel, togglePanel }) => {
  const t = useTranslations();

  return (
    <button
      data-testid="hamburger-menu"
      type="button"
      aria-haspopup="menu"
      aria-expanded={showPanel}
      aria-label={t('open_main_navigation_menu')}
      title={t('open_menu')}
      className={styles.container}
      onClick={togglePanel}
    >
      <svg
        className={styles.icon}
        xmlns="http://www.w3.org/2000/svg"
        width="52.8"
        height="40.8"
        viewBox="0 0 52.8 40.8"
      >
        <path
          fill="#fff"
          d="M11.4 11.4h29.9v1.9H11.4zm0 8.1h29.9v1.9H11.4zm0 7.9h29.9v1.9H11.4z"
        />
      </svg>
    </button>
  );
};

export default Hamburger;

Hamburger.propTypes = {
  togglePanel: propTypes.func.isRequired,
  showPanel: propTypes.bool.isRequired,
};
