import React, { useState } from 'react';

import { useTranslations } from '@unisporkal/localization';
import { AssetCategoryType } from '@unisporkal/sites';
import useIstockLinks from '../../../../hooks/useIstockLinks';
import MobilePopularCategories from '../MobilePopularCategories/MobilePopularCategories';
import MobileTrendingSearches from '../MobileTrendingSearches/MobileTrendingSearches';
import MobileListItem from '../MobileListItem/MobileListItem';
import MobileListOfLinks from '../MobileListOfLinks/MobileListOfLinks';
import MobileNavButton from '../MobileNavButton/MobileNavButton';
import MobileNavLink from '../MobileNavLink/MobileNavLink';
import styles from './MobileVectorsNav.module.scss';
import useHeaderPropsQuery from '../../../../hooks/queries/useHeaderPropsQuery';

interface MobileVectorsNavProps {
  showMediaNav: boolean;
  back: () => void;
}

const MobileVectorsNav = ({ showMediaNav, back }: MobileVectorsNavProps) => {
  const t = useTranslations();
  const leftPanelClass = showMediaNav ? styles.openMenu : styles.container;
  const links = useIstockLinks();
  const curatedContent = links.curatedContent(AssetCategoryType.VECTORS);
  const moreToDiscover = links.moreToDiscoverVectors();
  const [openPopularCategories, setOpenPopularCategories] = useState(false);
  const [openTrendingSearches, setOpenTrendingSearches] = useState(false);
  const {
    data: { trendingSearchLinksVectors },
  } = useHeaderPropsQuery();

  const popularCategoriesLinks = links.getCategoryLinks(
    AssetCategoryType.VECTORS
  );
  const vectorsHomeLink = links.getMobileVectorsLink();

  return (
    <nav className={leftPanelClass}>
      <div className={styles.backSection}>
        <button
          data-testid="mobile-nav-back"
          onClick={back}
          type="button"
          className={styles.backButton}
        >
          {t('site_specific.istock.back')}
        </button>
      </div>
      <ul className={styles.mobileMediaNav}>
        <MobileListItem>
          <MobileNavLink link={vectorsHomeLink} />
        </MobileListItem>

        <MobileListOfLinks
          links={curatedContent}
          gaListId="curated_content"
        />

        <MobileListItem testId="trending_searches">
          <MobileNavButton
            label={t('site_specific.istock.trending_searches')}
            onClick={() => setOpenTrendingSearches(true)}
          />
          <MobileTrendingSearches
            showMediaNav={openTrendingSearches}
            backHandler={() => setOpenTrendingSearches(false)}
            mediaSection={t('vectors')}
            trendingSearchLinks={trendingSearchLinksVectors}
          />
        </MobileListItem>

        <MobileListItem testId="popular_categories">
          <MobileNavButton
            label={t('site_specific.istock.popular_categories')}
            onClick={() => setOpenPopularCategories(true)}
          />
          <MobilePopularCategories
            showMediaNav={openPopularCategories}
            backHandler={() => setOpenPopularCategories(false)}
            mediaSection={t('vectors')}
            categoryLinks={popularCategoriesLinks}
          />
        </MobileListItem>

        <MobileListOfLinks
          links={moreToDiscover}
          gaListId="more_to_discover"
        />
      </ul>
    </nav>
  );
};

export default MobileVectorsNav;
