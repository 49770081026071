import React from 'react';
import { List } from '@unisporkal/alliance-ui-list';
import useMoreDropdownLinks from '../../../../hooks/useMoreLinksBuilder';
import MoreLinks from '../ListOfLinks/MoreLinks';

import styles from './MoreDropdown.module.scss';

// this component contains the "primary" or first dropdown of potentially
// multiple nested subnav components
const MoreDropdown = () => {
  const moreLinksList = useMoreDropdownLinks();

  return (
    <List
      className={styles.container}
      data-testid="openMoreDropdown"
    >
      <span className={styles.links}>
        <MoreLinks
          links={moreLinksList}
          gaListId="more"
        />
      </span>
    </List>
  );
};

export default MoreDropdown;
