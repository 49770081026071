import React from 'react';
import { List } from '@unisporkal/alliance-ui-list';
import MediaTypeNav from '../MediaTypeNav/MediaTypeNav';
import MusicLink from '../MusicLink/MusicLink';
import Resources from '../Resources/Resources';

import styles from './SubNav.module.scss';
import AIGeneratorLink from '../AIGeneratorLink/AIGeneratorLink';
import More from '../More/More';
import useHeaderPropsQuery from '../../../../hooks/queries/useHeaderPropsQuery';

const SubNav = () => {
  const {
    data: { isEligibleForPremiumPlusMusic },
  } = useHeaderPropsQuery();
  return (
    <List
      className={styles.container}
      data-testid="MainNavLeftList"
    >
      <MediaTypeNav
        dataNavLabel="Video"
        mediaType="video"
      />
      <MediaTypeNav
        dataNavLabel="Photos"
        mediaType="photos"
      />
      <MediaTypeNav
        dataNavLabel="Illustrations"
        mediaType="illustrations"
      />
      <MediaTypeNav
        dataNavLabel="Vectors"
        mediaType="vectors"
        isVector
      />
      {isEligibleForPremiumPlusMusic ? (
        <span
          className={styles.largeScreen}
          data-testid="musicLinkContainer"
        >
          <MusicLink />
        </span>
      ) : null}
      <AIGeneratorLink />
      <li className={styles.divider} />
      <span className={styles.largeScreen}>
        <Resources />
      </span>
      <span className={styles.mediumScreen}>
        <More />
      </span>
    </List>
  );
};

export default SubNav;
