import { useEffect, useState } from 'react';
import { ga4Track, navigationEvent } from '@unisporkal/ga4';

const usePanelsControls = (styles, overlay = false) => {
  const [showPanel, setShowPanel] = useState(false);

  const trackPanelToggle = () => {
    const panelAction = showPanel ? 'close' : 'open';
    const ga4Event = navigationEvent({
      link_text: `account_panel_${panelAction}`,
      navigation_selection: 'panel-controls',
    });

    ga4Track(ga4Event);
  };

  const togglePanel = () => {
    setShowPanel((prev) => !prev);
    trackPanelToggle();
  };

  const closePanel = () => {
    setShowPanel(false);
    trackPanelToggle();
  };

  // Move whole page to the left or right and add overlay
  useEffect(() => {
    const overlayNode = document.querySelector('.content_wrapper__mask');

    if (showPanel) {
      document.body.classList.add(styles);
      if (overlay) {
        overlayNode?.classList.remove('content_wrapper__mask--hidden');
        overlayNode?.addEventListener('click', closePanel);
      }
    } else {
      document.body.classList.remove(styles);
      if (overlay) {
        overlayNode?.classList.add('content_wrapper__mask--hidden');
        overlayNode?.removeEventListener('click', closePanel);
      }
    }

    return () => {
      document.body.classList.remove(styles);
      if (overlay) {
        overlayNode?.classList.add('content_wrapper__mask--hidden');
        overlayNode?.removeEventListener('click', closePanel);
      }
    };
  }, [showPanel]);

  return { togglePanel, showPanel, closePanel };
};

export default usePanelsControls;
