import React from 'react';
import useIstockLinks from '../../../../hooks/useIstockLinks';
import NavLink from '../NavLink/NavLink';

type Props = {
  currentLocation: string;
  onClickSignIn: () => void;
  onClickJoin: () => void;
};

const DesktopSeparatedButtons = ({
  currentLocation,
  onClickSignIn,
  onClickJoin,
}: Props) => {
  const links = useIstockLinks();
  const { signInLink, joinLink } = links.getSignInJoinLinks(currentLocation);

  return (
    <>
      <li data-testid="nav-sign_in">
        <NavLink
          dataNavLabel="SignIn"
          link={signInLink}
          onClick={onClickSignIn}
        />
      </li>
      <li data-testid="nav-join">
        <NavLink
          dataNavLabel="Join"
          link={joinLink}
          onClick={onClickJoin}
        />
      </li>
    </>
  );
};

export default DesktopSeparatedButtons;
