import { useMemo } from 'react';

const morePopularCategoriesEx = (categoriesList) => {
  const moreCategories = categoriesList.slice(0, 34);
  const newSortedSubset = [...moreCategories].sort((a, b) =>
    a.name > b.name ? 1 : -1
  );
  return newSortedSubset;
};

const useMorePopularCategories = (categoriesList) =>
  useMemo(() => morePopularCategoriesEx(categoriesList), [categoriesList]);

export default useMorePopularCategories;
