import React, { useState, useEffect, useRef } from 'react';
import { ga4Track, searchInteraction } from '@unisporkal/ga4';
import PropTypes from 'prop-types';
import { useTranslations } from '@unisporkal/localization';
import { IconButton } from '@unisporkal/alliance-ui-icon-button';
// eslint-disable-next-line import/no-unresolved
import SearchIcon from '../icons/searchWhite.svg?icon';
import styles from './SearchBarToggle.module.scss';

const SearchBarToggle = ({ currentPage }) => {
  const [isShow, setIsShow] = useState(false);
  const didMountRef = useRef(false);
  const t = useTranslations();

  const handleClick = () => {
    if (!['plans_and_pricing', 'premium_plus_video'].includes(currentPage)) {
      return null;
    }
    return setIsShow((oldValue) => !oldValue);
  };

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      return;
    }

    const ga4Event = searchInteraction({
      event_name: 'show_search_bar',
      search_selection: isShow ? 'show' : 'hide',
      ui_element_location: 'search_icon_toggle',
    });
    ga4Track(ga4Event);
  }, [isShow]);

  return (
    <>
      <li>
        <IconButton
          tabIndex={0}
          type="button"
          className={styles.searchToggleContainer}
          onClick={handleClick}
          data-testid="SearchBarToggle"
          title={t('search')}
          aria-label={t('search_all_media')}
        >
          <SearchIcon
            className={styles.searchIcon}
            id="search-bar-toggle"
          />
        </IconButton>
      </li>
      <li className={styles.divider} />
    </>
  );
};

SearchBarToggle.propTypes = {
  currentPage: PropTypes.string.isRequired,
};

export default SearchBarToggle;
