import React from 'react';
import { useTranslations } from '@unisporkal/localization';
import { ga4Track, navigationEvent } from '@unisporkal/ga4';
import styles from './SeeMorePopularCategories.module.scss';

interface SeeMorePopularCategoriesProps {
  categoryLinks: any;
  setIsSeeMoreActive: (boolean) => void;
}
const SeeMorePopularCategories = ({
  categoryLinks,
  setIsSeeMoreActive,
}: SeeMorePopularCategoriesProps) => {
  const t = useTranslations();
  const subNavigationTracking = (link) => {
    const ga4Event = navigationEvent({
      navigation_selection: 'see_more_popular_categories',
      link_text: link.gaName,
      link_url: link.href,
      ui_element_location: 'header_sub_nav',
    });
    ga4Track(ga4Event);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          {t('site_specific.istock.popular_categories')}
        </div>
        <button
          data-testid="see_more_popular_categories-back"
          onClick={() => setIsSeeMoreActive(false)}
          className={styles.backLink}
          type="button"
        >
          {`<${t('site_specific.istock.back')}`}
        </button>
      </div>
      <ul className={styles.categories}>
        {categoryLinks.map((link) => (
          <li
            className={styles.seeMoreListItem}
            key={link.gaName}
          >
            <a
              data-testid="see_more_popular_categories-subnav-link"
              href={link.href}
              className={styles.seeMoreItemLink}
              onClick={() => subNavigationTracking(link)}
            >
              {link.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SeeMorePopularCategories;
