import React from 'react';
import { List } from '@unisporkal/alliance-ui-list';
import useIstockLinks from '../../../../hooks/useIstockLinks';
import ListOfLinks from '../ListOfLinks/ListOfLinks';

import styles from './AIGeneratorDropdown.module.scss';

const AIGeneratorDropdown = () => {
  const links = useIstockLinks();
  const aiGenLinks = links.getAIGeneratorSubNavLinks();

  return (
    <List
      className={styles.container}
      data-testid="ai-generator-dropdown"
    >
      <ListOfLinks
        links={aiGenLinks}
        gaListId="ai_generator"
      />
    </List>
  );
};

export default AIGeneratorDropdown;
