import React from 'react';
import { ga4Track, navigationEvent } from '@unisporkal/ga4';
import { Link } from '@unisporkal/alliance-ui-link';
import { ListItem } from '@unisporkal/alliance-ui-list';
import styles from './AIGeneratorLink.module.scss';
import AIGeneratorDropdown from '../AIGeneratorDropdown/AIGeneratorDropdown';
import AIGeneratorIcon from '../../shared/icons/AIGenerator.svg?icon';
import useIstockLinks from '../../../../hooks/useIstockLinks';

const AIGeneratorLink = () => {
  const links = useIstockLinks();
  const isAIGenSubNavEnabled = links.aiGenSubnav;
  const {
    href,
    gaName,
    name: translatedLinkText,
  } = links.getAIGenerationLink();

  const navigationTracking = () => {
    const ga4Event = navigationEvent({
      link_text: gaName,
      link_url: href,
      ui_element_location: 'header',
      navigation_selection: gaName,
    });
    ga4Track(ga4Event);
  };

  return (
    <ListItem
      data-testid="ai-gen-link"
      className={styles.container}
    >
      <Link
        data-testid="aiGenLink"
        href={href}
        data-nav="nav_AI_Generator"
        className={styles.link}
        onClick={navigationTracking}
      >
        <AIGeneratorIcon
          className={styles.linkIcon}
          data-testid="ai-generator-icon"
        />
        <span className={styles.aiGeneratorText}>{translatedLinkText}</span>
        {isAIGenSubNavEnabled && (
          <span className={styles.caretContainer}>
            <span className={styles.caret} />
          </span>
        )}
      </Link>
      {isAIGenSubNavEnabled && (
        <div className={styles.dropdown}>
          <AIGeneratorDropdown />
        </div>
      )}
    </ListItem>
  );
};

export default AIGeneratorLink;
