/* eslint-disable import/no-unresolved */
import React from 'react';
import { useTranslations } from '@unisporkal/localization';
import IStockLogoDesktop from '../../../icons/IStockLogoDesktop.svg';
import IStockLogoMobile from '../../../icons/IStockLogoMobile.svg';
import styles from './IStockLogo.module.scss';

const IStockLogo = () => {
  const t = useTranslations();

  return (
    <>
      <img
        src={IStockLogoDesktop}
        className={styles.desktop}
        alt={t('istock_by_getty_images_logo')}
        data-testid="iStockLogo"
      />
      <img
        src={IStockLogoMobile}
        className={styles.mobile}
        alt={t('istock_logo')}
        data-testid="iStockLogoMobile"
      />
    </>
  );
};

export default IStockLogo;
