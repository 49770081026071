import React, { useState, useEffect } from 'react';
import { useTranslations } from '@unisporkal/localization';
import { AssetCategoryType } from '@unisporkal/sites';
import DropdownSection from '../DropdownSection/DropdownSection';
import useIstockLinks from '../../../../hooks/useIstockLinks';
import useMorePopularCategories from '../../../../hooks/useMorePopularCategories';
import useHeaderPropsQuery from '../../../../hooks/queries/useHeaderPropsQuery';

import styles from './VectorsDropdown.module.scss';

import SeeMorePopularCategories from '../SeeMorePopularCategories/SeeMorePopularCategories';
import ListOfLinks from '../ListOfLinks/ListOfLinks';

interface VectorsProps {
  isDropdownOpen: boolean;
}

const VectorsDropdown = ({ isDropdownOpen }: VectorsProps) => {
  const [seeMore, setSeeMore] = useState(false);
  const t = useTranslations();
  const links = useIstockLinks();
  const moreToDiscoverVectors = links.moreToDiscoverVectors();
  const popularCategoriesLinks = links.getCategoryLinks(
    AssetCategoryType.VECTORS
  );
  const dropdownPopularCategoriesLinks = popularCategoriesLinks.slice(0, 6);
  const morePopularCategoriesLinks = useMorePopularCategories(
    popularCategoriesLinks
  );
  const moreToDiscoverLinks = moreToDiscoverVectors.slice(0, 6);
  const {
    data: { trendingSearchLinksVectors },
  } = useHeaderPropsQuery();

  const curatedContentLinks = links.curatedContent(AssetCategoryType.VECTORS);

  // When this component gets hidden, this life cycle should undo any visible effects
  // of user interaction like Show More before it reopens

  useEffect(() => {
    if (!isDropdownOpen) {
      setSeeMore(false);
    }
  }, [isDropdownOpen]);

  return (
    <>
      <div
        data-testid={`${isDropdownOpen ? 'open' : 'closed'}VectorsDropdown`}
        className={`${styles.container} ${
          isDropdownOpen ? styles.active : styles.notActive
        }`}
      >
        <DropdownSection section={t('site_specific.istock.curated_content')}>
          <ListOfLinks
            gaListId="curated_content"
            links={curatedContentLinks}
          />
        </DropdownSection>

        <DropdownSection section={t('site_specific.istock.trending_searches')}>
          <ListOfLinks
            gaListId="Vectors_Trending"
            links={trendingSearchLinksVectors.slice(0, 6)}
            isTrendingSearches
          />
        </DropdownSection>

        <DropdownSection section={t('site_specific.istock.popular_categories')}>
          <ListOfLinks
            gaListId="popular_categories"
            links={dropdownPopularCategoriesLinks}
          />
          <button
            data-testid="popular_categories-see-more-button"
            onClick={() => setSeeMore(true)}
            className={`${styles.link} ${styles.seeMoreLink}`}
            type="button"
          >
            {t('see_all_keywords')}
          </button>
        </DropdownSection>

        <DropdownSection section={t('site_specific.istock.more_to_discover')}>
          <ListOfLinks
            gaListId="more_to_discover"
            links={moreToDiscoverLinks}
          />
        </DropdownSection>
      </div>

      <div
        className={`${styles.container} ${
          seeMore ? styles.active : styles.notActive
        }`}
      >
        <SeeMorePopularCategories
          categoryLinks={morePopularCategoriesLinks}
          setIsSeeMoreActive={setSeeMore}
        />
      </div>
    </>
  );
};

export default VectorsDropdown;
