import React, { useState, useEffect } from 'react';
import { useTranslations } from '@unisporkal/localization';
import { AssetCategoryType } from '@unisporkal/sites';
import useIstockLinks from '../../../../hooks/useIstockLinks';
import useMorePopularCategories from '../../../../hooks/useMorePopularCategories';
import styles from './VideoDropdown.module.scss';
import DropdownSection from '../DropdownSection/DropdownSection';
import SeeMorePopularCategories from '../SeeMorePopularCategories/SeeMorePopularCategories';
import ListOfLinks from '../ListOfLinks/ListOfLinks';
import useHeaderPropsQuery from '../../../../hooks/queries/useHeaderPropsQuery';

interface VideoDropdownProps {
  isDropdownOpen: boolean;
}

const VideoDropdown = ({ isDropdownOpen }: VideoDropdownProps) => {
  const [seeMore, setSeeMore] = useState(false);
  const {
    data: { trendingSearchLinksVideo },
  } = useHeaderPropsQuery();

  const t = useTranslations();
  const links = useIstockLinks();
  const curatedContentLinks = links.curatedContent(AssetCategoryType.VIDEOS);
  const popularCategoriesLinks = links.getCategoryLinks('videos');
  const dropdownPopularCategoriesLinks = popularCategoriesLinks.slice(0, 6);
  const morePopularCategoriesLinks = useMorePopularCategories(
    popularCategoriesLinks
  );
  const moreToDiscoverVideo = links.moreToDiscoverVideo();
  const moreToDiscoverLinks = moreToDiscoverVideo.slice(0, 6);

  // When this component gets hidden, this life cycle should undo any visible effects
  // of user interaction like Show More before it reopens
  useEffect(() => {
    if (!isDropdownOpen) {
      setSeeMore(false);
    }
  }, [isDropdownOpen]);

  const renderMainContent = () => (
    <div
      data-testid={`${isDropdownOpen ? 'open' : 'closed'}VideosDropdown`}
      className={`${styles.container} ${
        isDropdownOpen ? styles.active : styles.notActive
      }`}
    >
      <DropdownSection section={t('site_specific.istock.curated_content')}>
        <ListOfLinks
          gaListId="curated_content"
          links={curatedContentLinks}
        />
      </DropdownSection>
      <DropdownSection section={t('site_specific.istock.trending_searches')}>
        <ListOfLinks
          gaListId="Video_Trending"
          links={trendingSearchLinksVideo.slice(0, 6)}
          isTrendingSearches
        />
      </DropdownSection>
      <DropdownSection section={t('site_specific.istock.popular_categories')}>
        <ListOfLinks
          gaListId="popular_categories"
          links={dropdownPopularCategoriesLinks}
        />
        <button
          data-testid="popular_categories-see-more-button"
          onClick={() => setSeeMore(true)}
          className={`${styles.link} ${styles.seeMoreLink}`}
          type="button"
        >
          {t('see_all_keywords')}
        </button>
      </DropdownSection>
      <DropdownSection section={t('site_specific.istock.more_to_discover')}>
        <ListOfLinks
          gaListId="more_to_discover"
          links={moreToDiscoverLinks}
        />
      </DropdownSection>
    </div>
  );

  return (
    <>
      {renderMainContent()}
      <div
        className={`${styles.container} ${
          seeMore ? styles.active : styles.notActive
        }`}
      >
        <SeeMorePopularCategories
          categoryLinks={morePopularCategoriesLinks}
          setIsSeeMoreActive={setSeeMore}
        />
      </div>
    </>
  );
};

export default VideoDropdown;
