import React, { useState, useRef } from 'react';
import { useSiteMap } from '@unisporkal/sites';
import { useTranslations } from '@unisporkal/localization';

import DropdownContainer from '../DropdownContainer/DropdownContainer';
import VideoDropdown from '../VideoDropdown/VideoDropdown';
import PhotosDropdown from '../PhotosDropdown/PhotosDropdown';
import IllustrationsDropdown from '../IllustrationsDropdown/IllustrationsDropdown';
import VectorsDropdown from '../VectorsDropdown/VectorsDropdown';

import styles from './MediaTypeNav.module.scss';
import NavLink from '../NavLink/NavLink';

interface MediaTypeNavProps {
  dataNavLabel: string;
  mediaType: string;
  isVector?: boolean;
}

const MediaTypeNav = ({
  dataNavLabel,
  mediaType,
  isVector = false,
}: MediaTypeNavProps) => {
  const siteMap = useSiteMap();
  const t = useTranslations();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const isDropdownEnterRef = useRef(false);
  const isDropdownExitRef = useRef(false);
  const mediaLink = {
    gaName: mediaType,
    name: t(mediaType),
    href: mediaType && siteMap[mediaType](),
  };

  const renderMediaTypeDropdown = (): React.ReactNode => {
    switch (mediaType) {
      case 'video':
        return <VideoDropdown isDropdownOpen={isDropdownOpen} />;
      case 'photos':
        return <PhotosDropdown isDropdownOpen={isDropdownOpen} />;
      case 'illustrations':
        return <IllustrationsDropdown isDropdownOpen={isDropdownOpen} />;
      case 'vectors':
        return <VectorsDropdown isDropdownOpen={isDropdownOpen} />;
      default:
        return null;
    }
  };

  const handleMouseEnter = () => {
    isDropdownEnterRef.current = true;

    setTimeout(() => {
      if (isDropdownExitRef.current) {
        isDropdownExitRef.current = false;
        return null;
      }

      const maskNode = document.querySelector('.content_wrapper__mask');
      maskNode?.classList.remove('content_wrapper__mask--hidden');
      setIsDropdownOpen(true);
      return null;
    }, 200);

    return setTimeout(() => {
      isDropdownEnterRef.current = false;
    }, 300);
  };

  const handleMouseLeave = () => {
    isDropdownExitRef.current = true;

    setTimeout(() => {
      if (isDropdownEnterRef.current) {
        isDropdownEnterRef.current = false;
        return null;
      }

      const maskNode = document.querySelector('.content_wrapper__mask');
      maskNode?.classList.add('content_wrapper__mask--hidden');
      setIsDropdownOpen(false);
      return null;
    }, 200);

    return setTimeout(() => {
      isDropdownExitRef.current = false;
    }, 300);
  };

  return (
    <li
      className={
        isVector
          ? `${styles.isVector} ${styles.container}`
          : `${styles.container}`
      }
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      role="menuitem"
      data-testid={`nav-${dataNavLabel.toLowerCase()}`}
    >
      <NavLink
        dataNavLabel={dataNavLabel}
        link={mediaLink}
      />
      <span className={styles.caretContainer}>
        <span className={styles.caret} />
      </span>
      <nav className={isDropdownOpen ? styles.dropdown : styles.dropdownHidden}>
        <span className={styles.hoverPointer} />
        <DropdownContainer closeDropdown={() => setIsDropdownOpen(false)}>
          {renderMediaTypeDropdown()}
        </DropdownContainer>
      </nav>
    </li>
  );
};

export default MediaTypeNav;
