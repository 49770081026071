import React from 'react';
import { navigationEvent, ga4Track } from '@unisporkal/ga4';
import { List } from '@unisporkal/alliance-ui-list';
import { Link } from '@unisporkal/alliance-ui-link';
import styles from './ListOfLinks.module.scss';

interface ListOfLinks {
  gaListId: string;
  links: [
    {
      href: string;
      gaName: string;
      name: string;
      target: string;
    }
  ];
  resourcesLinks: boolean;
  isTrendingSearches?: boolean;
}

const ListOfLinks = ({
  gaListId,
  links,
  isTrendingSearches = false,
  resourcesLinks = false,
}) => {
  const ga4TrendingSearchesNavSelection = (navId) =>
    `${navId.toLowerCase()}_searches`;

  const subNavigationTracking = (link) => {
    const ga4Event = navigationEvent({
      navigation_selection: isTrendingSearches
        ? ga4TrendingSearchesNavSelection(gaListId)
        : gaListId,
      link_text: link.gaName,
      link_url: link.href,
      ui_element_location: 'header_sub_nav',
    });
    ga4Track(ga4Event);
  };

  return links.map((link) => (
    <List
      key={link.name}
      className={styles.linkList}
    >
      <Link
        data-testid={`${gaListId}-subnav-link`}
        href={link.href}
        className={resourcesLinks ? styles.resourcesLinks : styles.link}
        onClick={() => subNavigationTracking(link)}
        target={link.target ?? ''}
      >
        {link.name}
      </Link>
    </List>
  ));
};
export default ListOfLinks;
