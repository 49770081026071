/* eslint-disable import/no-unresolved */
import React, { useEffect, useState, useCallback } from 'react';
import propTypes from 'prop-types';
import { CNSTooltipMount } from '@unisporkal/utilities';
import AcountIcon from '../../../icons/account.svg?icon';
import styles from './AccountLink.module.scss';
import useUserDisplayName from '../../../../hooks/useUserDisplayName';
import useHeaderPropsQuery from '../../../../hooks/queries/useHeaderPropsQuery';

const AccountLink = ({ togglePanel }) => {
  const [displayRedDot, setDisplayRedDot] = useState(false);
  const {
    data: { firstName, lastName },
  } = useHeaderPropsQuery();
  const accountUsername = useUserDisplayName({ firstName, lastName });

  const handleAddEvent = useCallback((event, response) => {
    if (!response) return;
    setDisplayRedDot(true);
  });

  useEffect(() => {
    window.addEventListener('notifications.userPanelData', handleAddEvent);

    return () => {
      window.removeEventListener(
        'notifications.userPanelData',
        setDisplayRedDot(false)
      );
    };
  }, [handleAddEvent]);

  return (
    <li className={styles.wrapper}>
      {displayRedDot && <figure className={styles.notificationDot} />}
      <button
        type="button"
        aria-haspopup="menu"
        className={styles.container}
        onClick={togglePanel}
        data-testid="AccountIcon"
      >
        <AcountIcon />
        {accountUsername && (
          <span className={styles.name}>{accountUsername}</span>
        )}
      </button>
      <CNSTooltipMount type="onboarding_video_tooltip" />
    </li>
  );
};

export default AccountLink;

AccountLink.propTypes = {
  togglePanel: propTypes.func.isRequired,
};
