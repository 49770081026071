import React from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@unisporkal/localization';
import styles from './YourPlansSection.module.scss';

const AIGenerationsRemaining = ({ generationsRemaining }) => {
  const t = useTranslations();

  return (
    <tr
      data-testid="generationsRemaining"
      className={styles.remaining}
    >
      <td className={styles.count}>{generationsRemaining}</td>
      <td>
        <p>{t('ai_generations_count')}</p>
      </td>
    </tr>
  );
};

AIGenerationsRemaining.propTypes = {
  generationsRemaining: PropTypes.number.isRequired,
};

export default AIGenerationsRemaining;
