import React from 'react';
import styles from './DropdownContainer.module.scss';

interface DropdownContainerProps {
  children: React.ReactNode;
  closeDropdown: () => void;
}

const DropdownContainer = ({
  children,
  closeDropdown,
}: DropdownContainerProps) => (
  <nav
    className={styles.container}
    data-testid="dropdownContainer"
  >
    <button
      type="button"
      className={styles.closeButton}
      onClick={closeDropdown}
      aria-label="Close"
    />
    {children}
  </nav>
);

export default DropdownContainer;
