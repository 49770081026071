import React from 'react';

import NotificationsList from '../shared/NotificationsList/NotificationsList';
import FullHeader from './FullHeader/FullHeader';
import MinimalHeader from './MinimalHeader/MinimalHeader';
import styles from './Header.module.scss';

interface HeaderProps {
  currentPage: string;
  layout: string;
  linkDisabled?: boolean;
  showSearchBarToggle?: boolean;
}

const Header = ({
  layout,
  currentPage,
  linkDisabled = false,
  showSearchBarToggle = false,
}: HeaderProps) => {
  const headerLayout = () => {
    switch (layout) {
      case 'checkout':
      // intentional fallthrough
      case 'minimal':
        return <MinimalHeader linkDisabled={linkDisabled} />;
      case 'visual_gps':
        return <MinimalHeader isVisualGps />;
      default:
        return (
          <FullHeader
            showSearchBarToggle={showSearchBarToggle}
            currentPage={currentPage}
          />
        );
    }
  };

  return (
    <div className={styles.container}>
      <NotificationsList currentPage={currentPage} />
      {headerLayout()}
    </div>
  );
};

export default Header;
