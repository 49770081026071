import { AssetCategoryType } from '@unisporkal/sites';
import { useTranslations, useLocale } from '@unisporkal/localization';
import useIstockLinks from './useIstockLinks';
import useHeaderPropsQuery from './queries/useHeaderPropsQuery';

interface MoreLinksProps {
  gaName: string;
  name: string;
  href: string;
  secondaryItems: MoreLinksProps[];
}

export const useMoreDropdownLinks = (): MoreLinksProps[] => {
  const {
    data: { trendingSearchLinksVectors },
  } = useHeaderPropsQuery();

  const t = useTranslations();
  const links = useIstockLinks();
  const locale = useLocale();
  const finalLinkArray: MoreLinksProps[] = []; // Final array of links to be returned

  const curatedCollections = (): MoreLinksProps[] => {
    const collections = links.curatedContent(AssetCategoryType.VECTORS);

    const updatedCollections = collections.map((collection) => ({
      ...collection,
      secondaryItems: [],
    }));

    return updatedCollections;
  };

  const getSeasonalContent = (): MoreLinksProps[] => {
    const vectorsSeasonalContent = links.getSeasonalContentLinks(
      AssetCategoryType.VECTORS
    );

    // check for seasonal content
    // very likely that there isn't any seasonal content for vectors
    if (!vectorsSeasonalContent) {
      return [];
    }

    const updatedSeasonalContent = vectorsSeasonalContent.map((content) => ({
      ...content,
      secondaryItems: [],
    }));

    return updatedSeasonalContent;
  };

  const curatedContentObject = {
    gaName: 'curated-content',
    name: t('site_specific.istock.curated_content'),
    href: '',
    secondaryItems: [...curatedCollections(), ...getSeasonalContent()],
  };

  const trendingSearches = (): MoreLinksProps[] => {
    const trending = trendingSearchLinksVectors.slice(0, 6);

    if (!trending) {
      return [];
    }

    const updatedTrending = trending.map((trend) => ({
      ...trend,
      secondaryItems: [],
    }));

    return updatedTrending;
  };

  const trendingVectorSearchLinks = {
    gaName: 'trending-searches',
    name: t('site_specific.istock.trending_searches'),
    href: '',
    secondaryItems: [...trendingSearches()],
  };

  const showPopularCategories = (): MoreLinksProps[] => {
    const popularCategoriesLinks = links.getCategoryLinks(
      AssetCategoryType.VECTORS
    );

    const updatedPopularCategories = popularCategoriesLinks
      .sort((a, b) => a.name.localeCompare(b.name)) // Sort the array alphabetically by name
      .map((category) => ({
        ...category,
        secondaryItems: [],
      }));

    return updatedPopularCategories;
  };

  const popularCategories = {
    gaName: 'popular-categories',
    name: t('site_specific.istock.popular_categories'),
    href: '',
    secondaryItems: [...showPopularCategories()],
  };

  const moreToDiscoverLinks = (): MoreLinksProps[] => {
    const moreToDiscoverVectors = links.moreToDiscoverVectors();

    const updatedMoreToDiscover = moreToDiscoverVectors.map((discovery) => ({
      ...discovery,
      secondaryItems: [],
    }));

    return updatedMoreToDiscover;
  };

  const moreToDiscover = {
    gaName: 'more-to-discover',
    name: t('site_specific.istock.more_to_discover'),
    href: '',
    secondaryItems: [...moreToDiscoverLinks()],
  };

  // Vectors Title should be clickable and lead to FTLP
  const vectorsLinks: MoreLinksProps = {
    gaName: 'vectors',
    name: t('vectors'),
    href: links.getMobileVectorsLink().href,
    secondaryItems: [
      curatedContentObject,
      trendingVectorSearchLinks,
      popularCategories,
      moreToDiscover,
    ],
  };

  const music: MoreLinksProps = {
    gaName: 'music',
    name: t('music'),
    href: links.getMusicLink().href,
    secondaryItems: [],
  };

  const getResources = (localeParam: string): MoreLinksProps[] => {
    // Note that the resources links are different for english locale and other locales
    const englishLocale = localeParam === 'en-gb' || localeParam === 'en-us';
    // TODO: Update references below once redesigned header is permanent
    // https://app.clickup.com/t/86ayhcvmb
    const englishLocaleResources = links.resources();
    const modifiedResourcesLinksList = links.modifiedResources();

    const resources = englishLocale
      ? englishLocaleResources
      : modifiedResourcesLinksList;

    const updatedResources = resources.map((resource) => ({
      ...resource,
      secondaryItems: [],
    }));

    return updatedResources;
  };

  const resourcesLinks: MoreLinksProps = {
    gaName: 'resources',
    name: t('resources'),
    href: '',
    secondaryItems: [...getResources(locale)],
  };

  finalLinkArray.push(vectorsLinks, music, resourcesLinks);

  return finalLinkArray;
};

export default useMoreDropdownLinks;
