import React from 'react';
import { BoardToolboxProvider } from '@unisporkal/board-toolbox';
import Boards from '../Boards/Boards';
import PricingLink from '../PricingLink/PricingLink';
import SearchBarToggle from '../SearchBarToggle/SearchBarToggle';
import SignInJoin from '../SignInJoin/SignInJoin';
import styles from './MainNavRight.module.scss';
import UserAccountNav from '../UserAccountNav/UserAccountNav';
import useHeaderPropsQuery from '../../../../hooks/queries/useHeaderPropsQuery';

interface MainNavRightProps {
  showSearchBarToggle: boolean;
  currentPage: string;
}

const MainNavRight = ({
  showSearchBarToggle,
  currentPage,
}: MainNavRightProps) => {
  const {
    data: { isKnownUser },
  } = useHeaderPropsQuery();
  return (
    <nav
      className={styles.container}
      data-testid="MainNavRight"
    >
      <ul className={styles.navList}>
        {showSearchBarToggle && <SearchBarToggle currentPage={currentPage} />}

        <span className={styles.wrapper}>
          <PricingLink />
        </span>
        <BoardToolboxProvider>
          <Boards />
        </BoardToolboxProvider>

        {isKnownUser ? <UserAccountNav /> : <SignInJoin />}
      </ul>
    </nav>
  );
};

export default MainNavRight;
