import React from 'react';
import PropTypes from 'prop-types';
import AIGenerationsRemaining from './AIGenerationsRemaining';
import AIUnlimitedGenerationsActive from './AIUnlimitedGenerationsActive';

const AIGeneration = ({ generationsRemaining, hasUnlimitedAgreement }) =>
  hasUnlimitedAgreement ? (
    <AIUnlimitedGenerationsActive />
  ) : (
    <AIGenerationsRemaining generationsRemaining={generationsRemaining} />
  );

AIGeneration.propTypes = {
  hasUnlimitedAgreement: PropTypes.bool,
  generationsRemaining: PropTypes.number.isRequired,
};

AIGeneration.defaultProps = {
  hasUnlimitedAgreement: false,
};

export default AIGeneration;
