import React from 'react';
import { useTranslations } from '@unisporkal/localization';
import propTypes from 'prop-types';
import styles from './YourPlansSection.module.scss';

const TeamCreditsRemaining = ({ teamCreditsRemaining }) => {
  const t = useTranslations();
  const teamCreditsILox =
    teamCreditsRemaining === 1
      ? t('team_credit')
      : t('team_credits_capitalised');

  return (
    <tr
      data-testid="teamCreditsRemaining"
      className={`${styles.remaining} ${styles.teamCreditsRemaining}`}
    >
      <td className={styles.count}>{teamCreditsRemaining}</td>
      <td>
        <p>{teamCreditsILox}</p>
      </td>
    </tr>
  );
};

TeamCreditsRemaining.propTypes = {
  teamCreditsRemaining: propTypes.number.isRequired,
};

export default TeamCreditsRemaining;
