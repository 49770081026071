import React, { ReactNode } from 'react';

import styles from './MobileListItem.module.scss';

interface MobileListItemProps {
  children: ReactNode;
  testId?: string | null;
}

const MobileListItem = ({ children, testId = null }: MobileListItemProps) => (
  <li
    data-testid={testId ? `mobile-nav-${testId}` : null}
    className={styles.listItem}
  >
    {children}
  </li>
);

export default MobileListItem;
