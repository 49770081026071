import React from 'react';
import styles from './DropdownSection.module.scss';

// TODO: properly define children prop
interface DropdownSectionProps {
  section: string;
  children: any;
}
const DropdownSection = ({ section, children }: DropdownSectionProps) => (
  <nav className={styles.container}>
    <div
      className={styles.columnHeader}
      id={section}
    >
      {section}
    </div>
    <div className={styles.separator} />
    <ul
      className={styles.list}
      aria-labelledby={section}
    >
      {children}
    </ul>
  </nav>
);
export default DropdownSection;
