import React from 'react';
import { useTranslations } from '@unisporkal/localization';
import propTypes from 'prop-types';
import styles from './YourPlansSection.module.scss';

const MAP_SUBSCRIPTION_TYPE = {
  ppv: 'ppv_subscription_downloads_remaining',
  premium: 'premium_subscription_downloads_remaining',
  basic: 'basic_subscription_downloads_remaining',
};

const SubscriptionsRemaining = ({
  subscriptionDownloads,
  subscriptionType,
}) => {
  const t = useTranslations();

  return (
    <tr
      className={styles.remaining}
      data-testid="subsRemaining"
    >
      <td className={styles.count}>{subscriptionDownloads}</td>
      <td>
        <p>{t(MAP_SUBSCRIPTION_TYPE[subscriptionType])}</p>
      </td>
    </tr>
  );
};

SubscriptionsRemaining.propTypes = {
  subscriptionDownloads: propTypes.number.isRequired,
  subscriptionType: propTypes.string.isRequired,
};

export default SubscriptionsRemaining;
