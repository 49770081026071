/* eslint-disable complexity */
import React from 'react';
import usePlanInfoQuery from '../../../../hooks/queries/usePlanInfoQuery';
import UnlimitedAgreementPromo from './UnlimitedAgreementPromo/UnlimitedAgreementPromo';
import PremiumPlusVideoPromo from './PremiumPlusVideoPromo/PremiumPlusVideoPromo';

const PromotionalContent = () => {
  const { data: planInfoQuery } = usePlanInfoQuery();
  const { showSubscriptionAlert, customMedia } =
    planInfoQuery.agreementsOverview;
  const { showPremiumPlusVideoMessage } = planInfoQuery.ppvMessaging;

  const showUnlimitedMessage = () => {
    // if an agreement is present
    const hasEligibleAgreement =
      !customMedia?.hasUnlimitedAgreement &&
      !(customMedia?.generationsRemaining > 0);
    const hasAgreementsWarnings =
      customMedia?.warnings?.hasAgreementsWarnings || false;
    return (
      hasEligibleAgreement && !showSubscriptionAlert && !hasAgreementsWarnings
    );
  };

  const pickRandomPromo = () =>
    Math.random() <= 0.5 ? (
      <UnlimitedAgreementPromo />
    ) : (
      <PremiumPlusVideoPromo />
    );

  if (showUnlimitedMessage() && showPremiumPlusVideoMessage) {
    return pickRandomPromo();
  }

  if (showUnlimitedMessage()) {
    return <UnlimitedAgreementPromo />;
  }

  if (showPremiumPlusVideoMessage) {
    return <PremiumPlusVideoPromo />;
  }

  return null;
};

export default PromotionalContent;
