import React from 'react';
import { useTranslations } from '@unisporkal/localization';
import styles from './YourPlansSection.module.scss';
// eslint-disable-next-line import/no-unresolved
import Checkmark from '../icons/checkmark.svg?icon';

const AIUnlimitedGenerationsActive = () => {
  const t = useTranslations();

  return (
    <tr
      data-testid="activeUnlimitedGenerations"
      className={styles.remaining}
    >
      <td className={styles.count}>
        <Checkmark className={styles.checkmark} />
      </td>
      <td>
        <p>{t('unlimited_ai_generations')}</p>
      </td>
    </tr>
  );
};

export default AIUnlimitedGenerationsActive;
