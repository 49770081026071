import { useSuspenseQuery } from '@tanstack/react-query';
import { QUERY_TYPE } from '../../actions/types';
import useServiceClient from '../useServiceClient';

const usePlanInfoQuery = (options = {}) => {
  const { planInfoService } = useServiceClient();

  return useSuspenseQuery({
    queryKey: QUERY_TYPE.PLAN_INFO,
    queryFn: () => planInfoService.fetchPlanInfo(),
    ...options,
  });
};

export default usePlanInfoQuery;
