import React from 'react';
import { ListItem } from '@unisporkal/alliance-ui-list';
import useIstockLinks from '../../../../hooks/useIstockLinks';
import NavLink from '../NavLink/NavLink';

const MusicLink = () => {
  const links = useIstockLinks();
  const musicLink = links.getMusicLink();

  return (
    <ListItem data-testid="nav-music">
      <NavLink
        dataNavLabel="Music"
        link={musicLink}
      />
    </ListItem>
  );
};

export default MusicLink;
