import React from 'react';
import { ga4Track, navigationEvent } from '@unisporkal/ga4';
import { useSiteMap } from '@unisporkal/sites';

import styles from './HeaderLogo.module.scss';
import IStockLogo from '../icons/IStockLogo';

interface HeaderLogoProps {
  linkDisabled?: boolean;
}

const HeaderLogo = ({ linkDisabled }: HeaderLogoProps) => {
  const siteMap = useSiteMap();
  const href = siteMap.home();
  const navigationTracking = () => {
    const ga4Event = navigationEvent({
      link_text: 'iStock Photos homepage',
      link_url: href,
      ui_element_location: 'header',
      navigation_selection: 'header-logo',
    });
    ga4Track(ga4Event);
  };

  return (
    <section
      data-testid="nav-home"
      className={styles.container}
    >
      {linkDisabled ? (
        <IStockLogo />
      ) : (
        <a
          className={styles.link}
          href={href}
          data-nav="nav=head_Logo"
          data-testid="istock-header-logo"
          aria-label="iStock Photos homepage"
          onClick={navigationTracking}
        >
          <IStockLogo />
        </a>
      )}
    </section>
  );
};

export default HeaderLogo;
