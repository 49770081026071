import React from 'react';
import PropTypes from 'prop-types';

const BoardsIcon = ({ styles, title }) => (
  <svg
    className={styles}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <title>{title}</title>
    <g
      fill="#FFF"
      fillRule="nonzero"
    >
      <path d="M11 0v11H0V0z" />
      <path d="M14 14H3v-2.058h8.978V3H14z" />
    </g>
  </svg>
);

export default BoardsIcon;

BoardsIcon.propTypes = {
  title: PropTypes.string,
  styles: PropTypes.string,
};

BoardsIcon.defaultProps = {
  title: 'boards icon',
  styles: '',
};
