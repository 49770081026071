import React from 'react';
import styles from './MobileNavButton.module.scss';

interface MobileNavButtonProps {
  label: string;
  onClick: () => void;
}

const MobileNavButton = ({ label, onClick }: MobileNavButtonProps) => (
  <button
    data-testid="mobile-nav-button"
    className={styles.mediaAndCaret}
    onClick={onClick}
    type="button"
  >
    {label}
  </button>
);

export default MobileNavButton;
