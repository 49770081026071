import { useTranslations } from '@unisporkal/localization';
import { useSite } from '@unisporkal/sites';

// Get the current user's "localized" display name
// with fallbacks that vary by site.
// Logic copied from unisporkal-engine header_helper display_name
// but deleted espaws case
export default ({ firstName, lastName }) => {
  const username = undefined; // todo - get from hook?
  const site = useSite();
  const t = useTranslations();

  if (site.nickname === 'istock') {
    if (!firstName) {
      return t('account');
    }
  } else if (!firstName && !lastName) {
    return username.split('@')[0];
  }
  return t('display_name', { first_name: firstName, last_name: lastName });
};
