import React from 'react';
import { useLocale, useTranslations } from '@unisporkal/localization';
import useIstockLinks from '../../../../hooks/useIstockLinks';
import MobileListOfLinks from '../MobileListOfLinks/MobileListOfLinks';
import styles from './MobileResources.module.scss';

interface MobileResourcesProps {
  showMediaNav: boolean;
  back: () => void;
}

const MobileResources = ({ showMediaNav, back }: MobileResourcesProps) => {
  const t = useTranslations();
  const locale = useLocale();
  const englishLocale = locale === 'en-gb' || locale === 'en-us';
  const leftPanelClass = showMediaNav ? styles.openMenu : styles.container;
  const links = useIstockLinks();
  const resourcesList = englishLocale
    ? links.resources()
    : links.modifiedResources();

  return (
    <nav
      className={leftPanelClass}
      data-testid="MobileResourcesNav"
    >
      <div
        data-testid="mobile-nav-back"
        className={styles.backSection}
      >
        <button
          onClick={back}
          type="button"
          className={styles.backButton}
        >
          {t('site_specific.istock.back')}
        </button>
      </div>
      <ul className={styles.mobileMediaNav}>
        <MobileListOfLinks
          links={resourcesList}
          gaListId="resources"
        />
      </ul>
    </nav>
  );
};

export default MobileResources;
