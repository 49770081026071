import React from 'react';
import usePanelsControls from '../../../../hooks/usePanelsControls';
import MobileNav from '../MobileNav/MobileNav';
import Hamburger from '../icons/Hamburger';
import HeaderLogo from '../HeaderLogo/HeaderLogo';
import SubNav from '../SubNav/SubNav';
import styles from './MainNavLeft.module.scss';

const MainNavLeft = () => {
  const { showPanel, togglePanel } = usePanelsControls(styles.panelOpen, true);

  return (
    <nav
      className={styles.container}
      data-testid="MainNavLeft"
    >
      <MobileNav showPanel={showPanel} />
      <Hamburger
        togglePanel={togglePanel}
        showPanel={showPanel}
      />
      <HeaderLogo />
      <SubNav />
    </nav>
  );
};

export default MainNavLeft;
