import React, { Suspense, useState } from 'react';
import { lazyComponent } from '@unisporkal/react-lazy-component';
import { useTranslations } from '@unisporkal/localization';
import { ErrorBoundary } from '@unisporkal/react-instrumentation';
import BoardsIcon from '../icons/BoardsIcon';
import styles from './Boards.module.scss';
import { BoardsDropdownErrorComponent } from '../BoardsDropdown/BoardsDropdownItems.tsx';

const BoardsDropdown = lazyComponent(() =>
  import('../BoardsDropdown/BoardsDropdown')
);

const Boards = () => {
  const [showDropDown, setShowDropDown] = useState(false);
  const t = useTranslations();

  const openDropDown = () => setShowDropDown(true);

  return (
    <li className={styles.container}>
      <BoardsIcon
        title={t('boards')}
        styles={styles.icon}
      />
      <button
        onMouseEnter={openDropDown}
        onFocus={openDropDown}
        data-testid="nav-boards"
        aria-labelledby={t('boards')}
        type="button"
        className={styles.link}
      >
        {t('boards')}
      </button>
      <div className={styles.dropdown}>
        {showDropDown && (
          <Suspense fallback="">
            <ErrorBoundary
              appName="global-nav"
              component="BoardsDropdown"
              errorComponent={<BoardsDropdownErrorComponent />}
            >
              <BoardsDropdown />
            </ErrorBoundary>
          </Suspense>
        )}
      </div>
    </li>
  );
};

export default Boards;
