import React from 'react';
import styles from './FullHeader.module.scss';
import MainNavLeft from '../MainNavLeft/MainNavLeft';
import MainNavRight from '../MainNavRight/MainNavRight';

interface FullHeaderProps {
  showSearchBarToggle: boolean;
  currentPage: string;
}

const FullHeader = ({ showSearchBarToggle, currentPage }: FullHeaderProps) => (
  <header
    className={styles.container}
    data-testid="FullHeader"
  >
    <MainNavLeft />
    <MainNavRight
      showSearchBarToggle={showSearchBarToggle}
      currentPage={currentPage}
    />
  </header>
);

export default FullHeader;
