import React from 'react';
import { useTranslations } from '@unisporkal/localization';
import useIstockLinks from '../../../../hooks/useIstockLinks';
import MobileListOfLinks from '../MobileListOfLinks/MobileListOfLinks';
import styles from './MobileAiGenNav.module.scss';

interface MobileResourcesProps {
  showMediaNav: boolean;
  back: () => void;
}

const MobileResources = ({ showMediaNav, back }: MobileResourcesProps) => {
  const t = useTranslations();
  const leftPanelClass = showMediaNav ? styles.openMenu : styles.container;
  const links = useIstockLinks();
  const aiLinks = links.getAIGeneratorSubNavLinks();

  return (
    <nav
      className={leftPanelClass}
      data-testid="MobileAiGenNav"
    >
      <div
        data-testid="mobile-nav-back"
        className={styles.backSection}
      >
        <button
          onClick={back}
          type="button"
          className={styles.backButton}
        >
          {t('site_specific.istock.back')}
        </button>
      </div>
      <ul className={styles.mobileMediaNav}>
        <MobileListOfLinks
          links={aiLinks}
          gaListId="ai_generator"
        />
      </ul>
    </nav>
  );
};

export default MobileResources;
