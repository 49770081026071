import React from 'react';
import { ListItem } from '@unisporkal/alliance-ui-list';
import { useTranslations } from '@unisporkal/localization';
import ResourcesDropdown from '../ResourcesDropdown/ResourcesDropdown';

import styles from './Resources.module.scss';

const Resources = () => {
  const t = useTranslations();
  return (
    <ListItem
      data-testid="nav-resources"
      className={styles.container}
    >
      <button
        aria-labelledby="resources"
        type="button"
        className={styles.link}
        data-testid="resources"
      >
        {t('resources')}
      </button>
      <span className={styles.caretContainer}>
        <span className={styles.caret} />
      </span>
      <div className={styles.dropdown}>
        <ResourcesDropdown />
      </div>
    </ListItem>
  );
};

export default Resources;
