import React from 'react';
import { useTranslations } from '@unisporkal/localization';
import styles from './BoardsDropdown.module.scss';

interface BoardsDropdownWrapperProps {
  children: React.ReactNode;
}

export const BoardsDropdownWrapper: React.FC<BoardsDropdownWrapperProps> = ({
  children,
}) => (
  <ul
    className={styles.container}
    aria-label="boards-menu"
    role="menu"
  >
    {children}
  </ul>
);

export const BoardsDropdownError = () => {
  const t = useTranslations();

  return (
    <li
      className={styles.textMessage}
      role="menuitem"
      aria-label="error"
    >
      {t('recent_boards_error')}
    </li>
  );
};

export const BoardsDropdownErrorComponent = () => (
  <BoardsDropdownWrapper>
    <BoardsDropdownError />
  </BoardsDropdownWrapper>
);
