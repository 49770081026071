import React from 'react';
import PropTypes from 'prop-types';
import useIstockLinks from '../../../../hooks/useIstockLinks';
import NavLink from '../NavLink/NavLink';
import styles from './PricingLink.module.scss';
import useHeaderPropsQuery from '../../../../hooks/queries/useHeaderPropsQuery';

const PricingLink = ({ showDivider }) => {
  const {
    data: { showPricingLink },
  } = useHeaderPropsQuery();
  const links = useIstockLinks();
  const pricingLink = links.getPricingLink();

  if (showPricingLink) {
    return (
      <>
        <li data-testid="nav-pricing">
          <NavLink
            dataNavLabel="PlansAndPricing"
            link={pricingLink}
          />
        </li>
        {showDivider && <li className={styles.divider} />}
      </>
    );
  }

  return null;
};

export default PricingLink;

PricingLink.propTypes = {
  showDivider: PropTypes.bool,
};

PricingLink.defaultProps = {
  showDivider: true,
};
