import { useMemo } from 'react';
import { useSiteMap } from '@unisporkal/sites';
import { useTranslations } from '@unisporkal/localization';
import { useSearchPathBuilder } from '@unisporkal/search-url-builder';
import useHeaderPropsQuery from './queries/useHeaderPropsQuery';
import Links from '../header/components/istock/models/istockLinks';

const useIstockLinks = () => {
  const {
    data: {
      cacheSafeCountryCode,
      popularCategories,
      seasonalContent,
      hasActiveGenerationAgreement,
      aiGenSubnav,
    },
  } = useHeaderPropsQuery();

  const siteMap = useSiteMap();
  const t = useTranslations();
  const searchPathBuilder = useSearchPathBuilder();

  return useMemo(
    () =>
      new Links(
        siteMap,
        searchPathBuilder,
        t,
        popularCategories,
        seasonalContent,
        cacheSafeCountryCode,
        hasActiveGenerationAgreement,
        aiGenSubnav
      ),
    []
  );
};

export default useIstockLinks;
