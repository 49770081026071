import React from 'react';
import { ListItem } from '@unisporkal/alliance-ui-list';
import { useTranslations } from '@unisporkal/localization';
import MoreDropdown from './MoreDropdown';

import styles from './More.module.scss';

const More = () => {
  const t = useTranslations();

  return (
    <ListItem
      data-testid="nav-more"
      className={styles.container}
    >
      <button
        aria-labelledby="more"
        type="button"
        className={styles.link}
        data-testid="more"
      >
        {t('more_dot_dot_dot')}
      </button>
      <div className={`${styles.dropdown}`}>
        <MoreDropdown />
      </div>
    </ListItem>
  );
};

export default More;
