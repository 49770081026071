import React from 'react';
import { ga4Track, navigationEvent } from '@unisporkal/ga4';
import MobileListItem from '../MobileListItem/MobileListItem';

interface LinkProps {
  href: string;
  gaName: string;
  name: string;
}

interface MobileListOfLinksProps {
  gaListId: string;
  links: LinkProps[];
}

const MobileListOfLinks = ({ gaListId, links }: MobileListOfLinksProps) => {
  const subNavigationTracking = (link) => {
    const ga4Event = navigationEvent({
      navigation_selection: gaListId,
      link_text: link.gaName,
      link_url: link.href,
      ui_element_location: 'header_sub_nav',
    });
    ga4Track(ga4Event);
  };

  return links.map((link) => (
    <MobileListItem key={link.gaName}>
      <a
        data-testid={`${gaListId}-mobile-subnav-link`}
        href={link.href}
        onClick={() => subNavigationTracking(link)}
      >
        {link.name}
      </a>
    </MobileListItem>
  ));
};

export default MobileListOfLinks;
