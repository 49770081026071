const agreementWarnings = (t, siteMap, alertType, expiredAgreement) => {
  const alert = {
    pTagIloxKey: '',
    aTagIloxKey: '',
    aTagHref: '',
    checkoutHref: true,
    expiredAgreement,
  };
  switch (alertType) {
    case 'showZeroGenerations':
      alert.pTagIloxKey = t('out_of_generations');
      alert.aTagIloxKey = t('learn_more_arrow');
      alert.aTagHref = siteMap.aiGenerationPricing();
      alert.checkoutHref = false;
      alert.ga4CtaText = 'learn_more';
      alert.ga4NotificationDescription = 'ai_out_of_generations';
      alert.ga4Sku = 'AIGENISTOCK_BASIC100';
      break;
    case 'showExpired':
      alert.pTagIloxKey = t('renew_unlimited_ai');
      alert.aTagIloxKey = t('renew_now_arrow');
      alert.aTagHref = siteMap.checkout();
      alert.ga4CtaText = 'renew_now';
      alert.ga4NotificationDescription = 'ai_subscription_expired';
      alert.ga4Sku = expiredAgreement?.[0]?.sku;
      break;
    case 'showFailedPayment':
      alert.pTagIloxKey = t('unlimited_ai_payment_failed');
      alert.aTagIloxKey = t('update_payment');
      alert.aTagHref = siteMap.checkout();
      alert.ga4CtaText = 'update_payment';
      alert.ga4NotificationDescription = 'ai_payment_method_failed';
      alert.ga4Sku = expiredAgreement?.[0]?.sku;
      break;
    default:
      return null;
  }
  return alert;
};

export default agreementWarnings;
