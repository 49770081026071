import React from 'react';
import cx from 'classnames';
import useIstockLinks from '../../../../hooks/useIstockLinks';
import NavLink from '../NavLink/NavLink';
import styles from './EmphasizedSignInButton.module.scss';

type Props = {
  currentLocation: string;
  onClick: () => void;
};

const EmphasizedSignInButton = ({ currentLocation, onClick }: Props) => {
  const links = useIstockLinks();
  const { signInLink } = links.getSignInJoinLinks(currentLocation);

  return (
    <NavLink
      className={cx(styles.container, styles.emphasizedSignIn)}
      dataNavLabel="SignIn"
      link={signInLink}
      onClick={onClick}
    />
  );
};

export default EmphasizedSignInButton;
