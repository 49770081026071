import React, { useEffect } from 'react';
import { ga4Track, notificationInteraction } from '@unisporkal/ga4';
import { useTranslations } from '@unisporkal/localization';
import { useSiteMap } from '@unisporkal/sites';
import styles from '../PromotionalContent.module.scss';

const UnlimitedAgreementPromo = () => {
  const t = useTranslations();
  const siteMap = useSiteMap();

  const trackCtaClick = () => {
    const ga4Event = notificationInteraction({
      event: 'notification_cta_click',
      notification_description: 'ai_unlimited_merch',
      notification_type: 'information',
      cta_text: 'subscribe_now',
      ui_element_location: 'account_panel',
      item_id: 'AIGENISTOCK_MONTHLY',
    });
    ga4Track(ga4Event);
  };

  useEffect(() => {
    const ga4Event = notificationInteraction({
      event: 'notification_shown',
      notification_description: 'ai_unlimited_merch',
      notification_type: 'information',
      cta_text: undefined,
      ui_element_location: 'account_panel',
      item_id: 'AIGENISTOCK_MONTHLY',
    });
    ga4Track(ga4Event);
  }, []);

  return (
    <>
      <div
        className={styles.promo}
        data-testid="unlimitedAgreementPromo"
      >
        <figure className={styles.icon} />
        <div data-testid="unlimitedAgreementContainer">
          <p className={styles.unlimitedText}>
            {t('create_and_customize_unlimited')}
            <a
              href={siteMap.aiGenerationPricing()}
              onClick={trackCtaClick}
            >
              {t('subscribe_now')}
            </a>
          </p>
        </div>
      </div>
      <hr className={styles.horizontalDivider} />
    </>
  );
};

export default UnlimitedAgreementPromo;
