import React from 'react';
import { ga4Track, navigationEvent } from '@unisporkal/ga4';
import { List, ListItem } from '@unisporkal/alliance-ui-list';
import { Link } from '@unisporkal/alliance-ui-link';
import CaretRight from '../icons/caretRight.svg?icon';
import styles from './MoreLinks.module.scss';

interface NavLinkItem {
  href: string;
  gaName: string;
  name: string;
  target?: string;
  secondaryItems?: NavLinkItem[];
}

interface MoreLinks {
  gaListId: string;
  links: NavLinkItem[];
}

const MoreLinks = ({ gaListId, links }: MoreLinks) => {
  const subNavigationTracking = (link: NavLinkItem) => {
    const ga4Event = navigationEvent({
      navigation_selection: gaListId,
      link_text: link.gaName,
      link_url: link.href,
      ui_element_location: 'header_more_sub_nav',
    });

    ga4Track(ga4Event);
  };

  const nestedLinks = (link: NavLinkItem) => {
    if (!link) {
      return null;
    }

    if (!link.secondaryItems?.length) {
      return null;
    }

    const dropdownStyling = () => {
      const lastDropdownItems = [
        'curated-content',
        'trending-searches',
        'more-to-discover',
      ];

      const classes = [
        styles.dropdown,
        styles.container,
        styles.modifyMaxWidth,
      ];

      if (lastDropdownItems.some((item) => link.gaName.includes(item))) {
        classes.push(styles.isLastDropdown);
      }

      if (link.gaName === 'popular-categories') {
        classes.push(styles.isPopularCategories);
      }

      return classes.join(' ');
    };

    return (
      <div className={dropdownStyling()}>
        <MoreLinks
          links={link.secondaryItems}
          gaListId="moreItems"
        />
      </div>
    );
  };

  return (
    <List className={styles.linkList}>
      {links.map((link: NavLinkItem) => (
        <ListItem
          key={link.name}
          className={styles.listItem}
        >
          <Link
            data-testid={`${gaListId}-subnav-link`}
            data-nav={`nav_${link.gaName}`}
            data-ui-location="header_more_sub_nav"
            href={link.href}
            className={styles.link}
            onClick={() => subNavigationTracking(link)}
            target={link.target ?? ''}
          >
            {link.name}
          </Link>
          <span className={styles.caret}>
            {link.secondaryItems?.length ? <CaretRight /> : null}
          </span>
          {/* Nested dropdown */}
          {nestedLinks(link)}
        </ListItem>
      ))}
    </List>
  );
};
export default MoreLinks;
