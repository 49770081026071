import React from 'react';
import { RawHtml } from '@unisporkal/utilities';
import { ga4Track, notificationInteraction } from '@unisporkal/ga4';
import propTypes from 'prop-types';
import styles from './YourPlansSection.module.scss';

const trackSubscriptionAlertClick = (messageData) => {
  let sku;
  if (window?.tracking_data?.active_agreements?.RF) {
    sku = window.tracking_data.active_agreements.RF[0].Sku;
  }
  const ga4Event = notificationInteraction({
    event: 'notification_cta_click',
    notification_description: messageData.ga4NotificationDescription,
    cta_text: messageData.ga4CtaText,
    ui_element_location: 'account_panel',
    item_id: sku,
  });

  ga4Track(ga4Event);
};

const SubscriptionAlert = ({ messageData = {} }) => {
  if (!messageData || Object.keys(messageData) === 0) {
    return null;
  }

  return (
    <div
      className={styles.subscriptionAlert}
      data-testid="subscriptionAlert"
    >
      {messageData.hasAFigure && <figure />}
      <p>
        <RawHtml>
          {/* raw html used for <strong> tags added in ilox keys */}
          {messageData.pTagIloxKey}
        </RawHtml>
        {messageData.hasATag && (
          <a
            className={`${styles.serverAlertLink} ${
              messageData.hasNoWrapCta && styles.noWrapCta
            }`}
            href={messageData.aTagHref}
            data-nav={messageData.aTagDataNav}
            data-ui-location={messageData.dataUILocation}
            onClick={() => trackSubscriptionAlertClick(messageData)}
          >
            {messageData.aTagIloxKey}
          </a>
        )}
      </p>
    </div>
  );
};

SubscriptionAlert.propTypes = {
  messageData: propTypes.shape().isRequired,
};

export default SubscriptionAlert;
