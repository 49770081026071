import React from 'react';
import MobileListOfLinks from '../MobileListOfLinks/MobileListOfLinks';
import styles from './MobileTrendingSearches.module.scss';

interface TrendingSearchLinkProps {
  name: string;
  href: string;
  gaName: string;
}

interface MobileTrendingSearchesProps {
  showMediaNav: boolean;
  backHandler: () => void;
  mediaSection: string;
  trendingSearchLinks: TrendingSearchLinkProps[];
}

const MobileTrendingSearches = ({
  showMediaNav,
  backHandler,
  mediaSection,
  trendingSearchLinks,
}: MobileTrendingSearchesProps) => {
  const leftPanelClass = showMediaNav ? styles.openMenu : styles.container;

  return (
    <nav className={leftPanelClass}>
      <div className={styles.backSection}>
        <button
          data-testid="mobile-nav-back"
          onClick={backHandler}
          type="button"
          className={styles.backButton}
        >
          {mediaSection}
        </button>
      </div>
      <ul className={styles.mobileMediaNav}>
        <MobileListOfLinks
          links={trendingSearchLinks}
          gaListId="trending_searches"
        />
      </ul>
    </nav>
  );
};

export default MobileTrendingSearches;
