// video promo
const trackShowPremiumPlusVideoPromo = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  if (window.tracking_data) {
    window.tracking_data.merchandising_units = [
      'account_panel_premium_plus_video_sub_intro',
    ];
  }
  if (window.combinedGtmTracking != null) {
    window.combinedGtmTracking.gtmPushTracking('merchUnitInteraction', {
      merch_unit_interaction: 'Shown',
      merch_unit_selection: 'account_panel_premium_plus_video_sub_intro-shown',
      ui_element_location: 'header_account_panel',
    });
  }

  return null;
};

const trackClickPremiumPlusVideoPromoCTA = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  if (window.combinedGtmTracking != null) {
    window.combinedGtmTracking.gtmPushTracking('merchUnitInteraction', {
      merch_unit_interaction: 'Click',
      merch_unit_selection:
        'account_panel_premium_plus_video_sub_intro-clicked',
      ui_element_location: 'header_account_panel',
    });
  }

  return null;
};

// video upgrade
const trackShowPremiumPlusVideoUpgrade = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  if (window.tracking_data) {
    window.tracking_data.merchandising_units = ['account_panel_ppv_upgrade'];
  }
  if (window.combinedGtmTracking != null) {
    window.combinedGtmTracking.gtmPushTracking('merchUnitInteraction', {
      merch_unit_interaction: 'Shown',
      merch_unit_selection: 'account_panel_ppv_upgrade-shown',
      ui_element_location: 'header_account_panel',
    });
  }

  return null;
};

const trackClickPremiumPlusVideoUpgradeCTA = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  if (window.combinedGtmTracking != null) {
    window.combinedGtmTracking.gtmPushTracking('merchUnitInteraction', {
      merch_unit_interaction: 'Click',
      merch_unit_selection: 'account_panel_ppv_upgrade-clicked',
      ui_element_location: 'header_account_panel',
    });
  }

  return null;
};

export {
  trackShowPremiumPlusVideoPromo,
  trackClickPremiumPlusVideoPromoCTA,
  trackShowPremiumPlusVideoUpgrade,
  trackClickPremiumPlusVideoUpgradeCTA,
};
