import React from 'react';
import { useTranslations } from '@unisporkal/localization';
import propTypes from 'prop-types';
import styles from './YourPlansSection.module.scss';

const CreditsRemaining = ({ creditsAvailable }) => {
  const t = useTranslations();

  const creditsILoxKey =
    creditsAvailable === 1 ? t('singular_credit') : t('istock_credits');

  return (
    <tr
      data-testid="creditsRemaining"
      className={styles.remaining}
    >
      <td className={styles.count}>{creditsAvailable}</td>
      <td>
        <p>{creditsILoxKey}</p>
      </td>
    </tr>
  );
};

CreditsRemaining.propTypes = {
  creditsAvailable: propTypes.number.isRequired,
};

export default CreditsRemaining;
