import React, { useState } from 'react';
import { useExperience } from '@unisporkal/experiences';
import { useTranslations } from '@unisporkal/localization';
import styles from './MobileNav.module.scss';
import useIstockLinks from '../../../../hooks/useIstockLinks';
import MobileVideoNav from '../MobileVideoNav/MobileVideoNav';
import MobilePhotosNav from '../MobilePhotosNav/MobilePhotosNav';
import MobileIllustrationsNav from '../MobileIllustrationsNav/MobileIllustrationsNav';
import MobileVectorsNav from '../MobileVectorsNav/MobileVectorsNav';
import MobileNavButton from '../MobileNavButton/MobileNavButton';
import MobileNavLink from '../MobileNavLink/MobileNavLink';
import MobileResources from '../MobileResources/MobileResources';
import MobileAiGenNav from '../MobileAiGenNav/MobileAiGenNav';

interface MobileNavProps {
  showPanel: boolean;
}

const MobileNav = ({ showPanel }: MobileNavProps) => {
  const leftPanelClass = showPanel ? styles.openMenu : styles.closeMenu;
  const t = useTranslations();
  const isAIGenSubNavEnabled = useExperience('AIGenSubnav').isActive();
  const [expandVideoNav, setExpandVideoNav] = useState(false);
  const [expandPhotosNav, setExpandPhotosNav] = useState(false);
  const [expandIllustrationsNav, setExpandIllustrationsNav] = useState(false);
  const [expandVectorsNav, setExpandVectorsNav] = useState(false);
  const [expandResourcesNav, setExpandResourcesNav] = useState(false);
  const [expandAiGenNav, setExpandAiGenNav] = useState(false);
  const links = useIstockLinks();
  const musicLink = links.getMusicLink();
  const visualGpsLink = links.getVisualGpsLink();
  const aiGenerationLink = links.getAIGenerationLink();
  const resourcesDropdown = () => (
    <li
      data-testid="mobile-nav-resources"
      className={styles.menuItem}
    >
      <MobileNavButton
        label={t('resources')}
        onClick={() => setExpandResourcesNav(true)}
      />
      <MobileResources
        showMediaNav={expandResourcesNav}
        back={() => setExpandResourcesNav(false)}
      />
    </li>
  );

  const aiGenNavOption = () => {
    if (isAIGenSubNavEnabled) {
      return (
        <>
          <MobileNavButton
            label={t('ai_generator_title_casing')}
            onClick={() => setExpandAiGenNav(true)}
          />
          <MobileAiGenNav
            showMediaNav={expandAiGenNav}
            back={() => setExpandAiGenNav(false)}
          />
        </>
      );
    }
    return <MobileNavLink link={aiGenerationLink} />;
  };

  return (
    <nav className={leftPanelClass}>
      <ul className={styles.container}>
        <li
          data-testid="mobile-nav-video"
          className={styles.menuItem}
        >
          <MobileNavButton
            label={t('video')}
            onClick={() => setExpandVideoNav(true)}
          />
          <MobileVideoNav
            showMediaNav={expandVideoNav}
            back={() => setExpandVideoNav(false)}
          />
        </li>

        <li
          data-testid="mobile-nav-photos"
          className={styles.menuItem}
        >
          <MobileNavButton
            label={t('photos')}
            onClick={() => setExpandPhotosNav(true)}
          />
          <MobilePhotosNav
            showMediaNav={expandPhotosNav}
            back={() => setExpandPhotosNav(false)}
          />
        </li>

        <li
          data-testid="mobile-nav-illustrations"
          className={styles.menuItem}
        >
          <MobileNavButton
            label={t('illustrations')}
            onClick={() => setExpandIllustrationsNav(true)}
          />
          <MobileIllustrationsNav
            showMediaNav={expandIllustrationsNav}
            back={() => setExpandIllustrationsNav(false)}
          />
        </li>
        <li
          data-testid="mobile-nav-vectors"
          className={styles.menuItem}
        >
          <MobileNavButton
            label={t('vectors')}
            onClick={() => setExpandVectorsNav(true)}
          />
          <MobileVectorsNav
            showMediaNav={expandVectorsNav}
            back={() => setExpandVectorsNav(false)}
          />
        </li>
        <li
          data-testid="mobile-nav-music"
          className={styles.menuItem}
        >
          <MobileNavLink link={musicLink} />
        </li>

        <li
          data-testid="mobile-nav-visual_gps_insights"
          className={styles.menuItem}
        >
          <MobileNavLink link={visualGpsLink} />
        </li>

        <li
          data-testid="mobile-nav-ai_gen"
          className={styles.menuItem}
        >
          {aiGenNavOption()}
        </li>

        {resourcesDropdown()}
      </ul>
    </nav>
  );
};

export default MobileNav;
